import React, { useState, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SimpleInput from '../../form/input';
import SimpleTextArea from '../../form/textArea';
import AddImage from '../../form/addImage';
import Utils from '../../../utils/images';
import firebase from 'firebase';
import { Constants } from '../../../objects/constants';
import SaveButton from '../../saveButton';

const AddFlyer = (props: { classId?: string, imageUrl?: string }) => {

    const imageRef = useRef<JSX.Element>(null)
    
    const [flyerImage, setFlyerImage] = useState<File | Blob | undefined>()
    
    const [loading, setLoading] = useState(false)

    const inputHeader = {
        color: "#575757"
    }

    const saveFlyer = () => {
        if (!props.classId || !flyerImage) {
            return
        }

        const db = firebase.firestore()
        var imageRef = firebase.storage().ref().child(`masterclasses/${props.classId!}/flyer.jpg`)

        setLoading(true)

        imageRef.put(flyerImage).then(async snapshot => {
            let downloadUrl = await snapshot.ref.getDownloadURL()
            await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({
                flyerUrl: downloadUrl
            })
            setLoading(false)
        }).catch(error => {
            console.log("Error saving image ", error)
        })
    }

    // When the image has been selected, than we compress it and save it as a state object
    const onImagesAdded = async (images: File[]) => {
        const utils = new Utils()

        if (images.length == 0) {
            return
        }

        // Compress the image
        const compressedImage = await utils.compressImage(images[0])

        if (!compressedImage) { return }

        setFlyerImage(compressedImage)

    }

    return (
        <Container>
            
            <AddImage imageUrl={props.imageUrl} onImagesAdded={onImagesAdded} ref={imageRef} />

            <SaveButton loading={loading} action={saveFlyer} title="Save Flyer" />

        </Container>
    )

}

export default AddFlyer