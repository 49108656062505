import React from 'react'
import { Accordion, Card, Button, Container, Row, Col } from 'react-bootstrap'
import TrainerInformation from './sections/trainers/trainerInformation'

import AccordionSection from './sections/accordionSection'
import AddCertificate from './sections/certificate/addCertficate'
import AddClassPhotos from './sections/photos/addClassPhotos'
import { Masterclass } from '../objects/masterclass'
import ClassType from './sections/classType/classType'
import AddFlyer from './sections/flyer/addFlyer'

const CreateClassAccordion = (props: { masterclass?: Masterclass }) => {
    
    return (
        <div>
            <Accordion className="my-5">
                <Card>
                    <Card.Header style={{ color: "white", backgroundColor: "#8e2d8e" }}>
                        <strong>Create Class</strong>
                    </Card.Header>

                </Card>
                
                <AccordionSection
                    eventKey="0"
                    finished={props.masterclass?.trainer != null}
                    saveButtonText="Save Trainer Information"
                    headerText="Click to add trainer information"
                    component={ <TrainerInformation classId={props.masterclass?.id} trainer={ props.masterclass?.trainer } /> }
                />

                <AccordionSection
                    eventKey="1"
                    finished={props.masterclass?.basicInfo != null}
                    saveButtonText="Save Class Type Information"
                    headerText="Click to add class type information"
                    component={ <ClassType classId={props.masterclass?.id} classInformation={ props.masterclass?.basicInfo } /> }
                />

                <AccordionSection
                    eventKey="2"
                    finished={props.masterclass?.photoUrls != null}
                    saveButtonText="Upload Photos"
                    headerText="Click to add photos for this class"
                    component={ <AddClassPhotos classId={props.masterclass?.id} imageUrls={props.masterclass?.photoUrls} /> }
                />

                <AccordionSection
                    eventKey="3"
                    finished={props.masterclass?.certificateUrl != null}
                    saveButtonText="Upload Certificate"
                    headerText="Click to upload your certificate"
                    component={ <AddCertificate imageUrl={props.masterclass?.certificateUrl} classId={props.masterclass?.id} /> }
                />

                <AccordionSection
                    eventKey="5"
                    finished={props.masterclass?.flyerUrl != null}
                    saveButtonText="Upload Flyer for Class"
                    headerText="Click to add flyer"
                    component={ <AddFlyer classId={props.masterclass?.id} imageUrl={ props.masterclass?.flyerUrl } /> }
                />


            </Accordion>
        </div>
    )
}

export default CreateClassAccordion