import { constants } from 'buffer'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, CardColumns } from 'react-bootstrap'
import ClassElement from '../../components/classelement'
import Marketplace from '../../components/marketplace'
import { Constants } from '../../objects/constants'
import { Masterclass } from '../../objects/masterclass'
import HorizontalBanner from '../../components/horizontalBanner'

const MarketplacePage = () => {

    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <div className="jumbotron" style={{ backgroundColor: Constants.Purple, color: "white" }}>
                        <h1 className="display-5" >Masterclasses Marketplace</h1>
                        <p className="lead"></p>
                        <hr className="my-4" style={{ backgroundColor: "white" }} />
                        <p>From here you can view classes available for purchase without logging in. 😊👍 Log in to view your dashboard and to see classes you've already purchased.</p>
                    </div>
                </Col>
            </Row>

            <HorizontalBanner bannerImage="/banners/horizontal-banner-1.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

            <Marketplace />

            <HorizontalBanner bannerImage="/banners/horizontal-banner-2.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

            <Row style={{ backgroundColor: Constants.LightGray }} className="my-3">
                <Col xs="12" className="justify-content-center align-items-center my-3">
                    {Constants.COPYRIGHT_TEXT}
                </Col>
            </Row>
        </Container>
        
    )
}

export default MarketplacePage