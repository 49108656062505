import React from 'react'
import { Masterclass } from '../objects/masterclass'
import { Row, Col, Card, Button, Container, Image } from 'react-bootstrap'
import { isMaster } from 'cluster'
import FireService from '../firebaseService'
import { Constants } from '../objects/constants'
import useCoupon from '../hooks/useCoupon'

/**
 * Responsible for displaying class information to the user in a bootstrap card
 * 
 * @param props Masterclass is the masterclass object to display, 
 * isClassPurchased: Whether the class has been purchased by this user or not
 * revoking: Whether the revoking functionality should be added to this element
 * revokingClass: If revoking, then this should be a function that should be called when revoking the class. 
 * showEditClassButton: Whether to show the button to allow the user to edit a class
 * purchasing: Set this if purchasing, make sure that show coupon is set. If showCoupon is set then we show the coupon information at the bottom of this element
 */
const ClassElement = (props: {
    masterclass: Masterclass, isClassPurchased?: boolean, revoking?: boolean, revokingClass?: any, showEditClassButton?: boolean, purchasing?: {
        showCoupon: boolean
    }
}) => {

    const { coupon } = useCoupon(props.masterclass.id ?? "")

    const getClassColor = (masterclass: Masterclass) => {
        switch (masterclass.status) {
            case "live":
                return Constants.Purple;
            case "review":
                return "#ea8a25";
            default:
                return "#c84231"
        }
    }

    const getClassStatus = (masterclass: Masterclass) => {
        switch (masterclass.status) {
            case "live":
                if (props.isClassPurchased) {
                    return ""
                }

                return "Live"
            case "review":
                return "In Review"
            case "down":
                return "Not Currently Active"
            default:
                return "Not Finished"
        }
    }

    const viewClass = (masterclass: Masterclass) => {
        if (!masterclass.id) { return }

        if (props.revoking) { return "" }

        switch (masterclass.status) {
            case undefined:
                return `/createclass/${masterclass.id}/${masterclass.basicInfo?.name ?? ""}`
            case 'live':
                if (props.isClassPurchased) {
                    return `/class/${masterclass.id}/${masterclass.basicInfo?.name ?? ""}/true`
                }

                return `/class/${masterclass.id}/${masterclass.basicInfo?.name ?? ""}/false`
            default:
                return `/class/review/${masterclass.id}/${masterclass.basicInfo?.name ?? ""}`
        }
    }

    const classActionButtonText = (masterclass: Masterclass) => {

        if (props.revoking) { return 'Revoke Class' }

        switch (masterclass.status) {
            case "live":
                return "View Class"
            case "review":
                return "View Class Preview"
            case "down":
                return "View Class Preview"
            default:
                return "Finish Class"
        }
    }

    const buttonClicked = () => {
        if (!props.revoking) { return }

        props.revokingClass(props.masterclass.id)
    }

    const ticketsLeft = () => {
        return (props.masterclass?.tranche?.size ?? 7) - (props.masterclass?.tranche?.ticketsSold ?? 0)
    }

    const ActionButton = () => {
        return (
            <div>
                {
                    !props.purchasing &&
                    <Button className="mt-1" onClick={buttonClicked} href={viewClass(props.masterclass)} style={{ backgroundColor: getClassColor(props.masterclass), border: "none", color: "white" }} >
                        Click Here to {classActionButtonText(props.masterclass)}
                    </Button>
                }
                {
                    props.purchasing && props.masterclass.basicInfo?.price != 0 &&
                    <Button className="mt-1" href={`/class/purchase/${props.masterclass.id}`} style={{ backgroundColor: getClassColor(props.masterclass), border: "none", color: "white" }}>
                        See prices &amp; Class Details
                    </Button>
                }
                {
                    props.purchasing && !props.masterclass.basicInfo?.price &&
                    <Button className="mt-1" href={`/class/purchase/${props.masterclass.id}`} style={{ backgroundColor: getClassColor(props.masterclass), border: "none", color: "white" }}>
                        Get class for FREE
                    </Button>
                }
                {
                    props.showEditClassButton &&
                    <div>
                        <hr />
                        <Button className="mt-1" onClick={buttonClicked} href={`/createclass/${props.masterclass.id}/${props.masterclass.basicInfo?.name ?? ""}`} style={{ backgroundColor: '#e74c3c', border: "none", color: "white" }} >
                            Edit Class
                    </Button>
                    </div>
                }
            </div>
        )
    }

    return (

        <Card>
            <Card.Header>
                <Container style={{ paddingLeft: "0px" }}>
                    <Row className="align-items-center">
                        <Col xs="12">
                            <Image src={`${props.masterclass.trainer?.imageUrl}/100x100`} height="50" width="50" roundedCircle />
                        </Col>
                        <Col xs="12" className="mt-2">
                            <div>
                                Class taught by:
                            </div>
                            <div className="align-middle">
                                <strong>{props.masterclass.trainer?.name}</strong>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Img variant="bottom" src={props.masterclass.flyerUrl} />
            <Card.Body>
                <Card.Title>
                    <h3>
                        {props.masterclass.basicInfo?.name ?? "No Name Yet"}
                        <div>
                            <strong>{getClassStatus(props.masterclass)}</strong>
                        </div>
                    </h3>
                </Card.Title>
                <Card.Subtitle>
                    <div className="my-3">
                        Class taught by <strong>{props.masterclass.trainer?.name}</strong>
                    </div>
                </Card.Subtitle>
                <Card.Text>
                    {
                        !props.purchasing &&
                        <div>
                            <div className="text-clamp-12">
                                {
                                    props.masterclass.basicInfo?.description &&
                                    <div>
                                        <span className="ellipsis" dangerouslySetInnerHTML={{ __html: props.masterclass.basicInfo?.description }} />
                                    </div>
                                }
                            </div>
                        ... <a href={`${viewClass(props.masterclass)}`}>See More</a>
                        </div>
                    }

                    {
                        coupon && props.purchasing?.showCoupon &&
                        <div className="my-2">
                            <div className="alert alert-success">
                                Don't have a coupon code? Use code <strong>{coupon.concatedCode}</strong> and save <strong>${coupon.savings}</strong> when you register before <strong>${coupon.expiresString}</strong>
                            </div>
                            <div className="alert alert-success">
                                Only <strong>{ticketsLeft()}</strong> openings left at today's price...
                            </div>
                        </div>
                    }
                </Card.Text>
                <ActionButton />
            </Card.Body>
        </Card >

    )
}

export default ClassElement