import React, { useState, useEffect } from 'react'
import { Col, Form, FormControl, Row } from 'react-bootstrap'

const SimpleInput = (props: { 
        placeholder: string,
        headerName: string,
        header: boolean,
        onChange: any
        value: string,
        onFocus?: any,
        onBlur?: any,
        customClassName?:string
        disabled?: boolean,
        description?: string
    }) => {
    
    const [value, setStateValue] = useState(props.value)

    useEffect(() => {
        setStateValue(props.value ?? "")
    }, [props.value])

    const simpleInputStyle = (): {} => {
        if (props.header === true) {
            return {
                fontSize: '18px',
                fontWeight: 'bold'
            }
        }
        return {
            fontWeight: '600',
            fontSize: '16px',
        }
    }

    const setValue = (value: any) => {
        setStateValue(value.target.value)
        props.onChange(value.target.value)
    }

    const lineStyle = {
        marginLeft: '15px'
    }

    return (
        <div>
            <Row>
                <Col xs="12" className="my-3" style={{ fontWeight: "bold" }}>
                    { props.headerName }
                </Col>

                {
                    props.description && 
                    <Col className="mb-3" style={{color: "#8c8c8c"}}>
                        {props.description}
                    </Col>
                }

                <Col xs="12" className={props.customClassName}>
                    <FormControl
                        value={value}
                        onChange={setValue}
                        disabled={props.disabled}
                        size="lg"
                        type="text"
                        placeholder={props.placeholder}
                        style={simpleInputStyle()}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur} />
                </Col>
            </Row>

        </div>

    )

}

export default SimpleInput