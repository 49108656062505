import firebase from "firebase";
import { Constants } from "./objects/constants";
import { UserModuleDocument, UserDocument } from "./objects/userDocument";
import { LogoutOptions } from "@auth0/auth0-react";
import { Masterclass } from "./objects/masterclass";

class FireService {

    /**
     * If the user is banned, then log them out immediately
     * 
     * @param userId The id of the user to check if they're banned or not
     * @param auth0Logout Logout function provided by Auth0
     */
    static isBanned(userId: string, auth0Logout: ((options?: LogoutOptions) => void)):Promise<boolean> {
        return new Promise((resolve, reject) => {
            const db = firebase.firestore()
            console.log('checking if user with user id %s is banned', userId)
            db.collection(Constants.BANNED_USERS).doc(userId).get().then(result => {
                if (result.exists) {                    
                    console.log('user with userId %s is banned', userId)
                    FireService.logout(auth0Logout)
                    resolve(true)
                    return
                }
                console.log('user with userId %s is not banned', userId)
                resolve(false)
            }).catch(error => reject(error))
        })
    }

    /** 
     * Log the user out of Auth0 and Firebase 
     * 
     * @param options?: This is the logout function provided by Auth0
    */
    static logout(auth0Logout: ((options?: LogoutOptions) => void)) {
        FireService.removeIpAddress().then(_ => {
            console.log('signing out user...')
            auth0Logout({ returnTo: window.location.origin })
            firebase.auth().signOut()
        })
    }

    /**
     * Get all the free classes that are currently avaiable for the conference
     * 
     * These conference classes are set in Firestore
     */
    static getFreeClassesWithTag(tag: string) {
        return new Promise((resolve, reject) => {
            const db = firebase.firestore()
            console.log("searching for free classes...")
            db.collection(Constants.FREE_CLASSES_COLLECTION).where('tag', '==', tag).get().then(snapshot => {
                snapshot.docs.map(doc => doc.data()).map(data => {
                    data.classes.map((classId: string) => {
                        console.log('assigning free class with classId: %s', classId)
                        var purchase: Record<string, boolean> = {}
                        purchase[classId] = true
                        FireService.updateUser({ purchasedClasses: purchase })
                    })
                })

                resolve(true)
            })
        })

    }

    static setFarthestPosition(moduleId: string, progress: number) {
        var moduleProgress: Record<string, any> = {}
        moduleProgress[moduleId] = { progress: progress }

        FireService.updateUser({
            modules: moduleProgress
        })
    }

    static progressForModule(userDoc: UserDocument, moduleId: string) {
        if (!userDoc.modules) { return 0 }
        if (!userDoc.modules[moduleId]) { return 0 }

        return userDoc.modules[moduleId].progress
    }

    static rateModule(moduleId: string, rate: number, ratingId: string, userId: string) {
        const db = firebase.firestore()
        db.collection(Constants.RATINGS_COLLECTION).doc(ratingId).set({
            moduleId: moduleId,
            rating: rate,
            userId: userId
        }, { merge: true })
    }

    static saveFeedbackForModule(moduleId: string, ratingId: string, feedback: string, userId: string) {
        const db = firebase.firestore()
        db.collection(Constants.RATINGS_COLLECTION).doc(ratingId).set({
            moduleId: moduleId,
            feedback: feedback,
            userId: userId
        }, { merge: true })
    }

    static moduleIsStarted(userDoc: UserDocument, moduleId: string) {
        if (!userDoc.modules) { return false }
        if (!userDoc.modules[moduleId]) { return false }

        return userDoc.modules[moduleId].status != undefined
    }

    static userIsAdmin(userId: string) {
        // 'auth0|5f3703914766830067ea7ef3'
        return ['auth0|5f56a24218d764006843926b', 'google-oauth2|104308725786721295379'].indexOf(userId) != -1
    }

    static moduleIsFinished(userDoc: UserDocument, moduleId: string) {
        if (!userDoc.modules) { return false }
        if (!userDoc.modules[moduleId]) { return false }

        return userDoc.modules[moduleId].status == Constants.MODULE_FINISHED
    }

    static classIsPurchased (userDoc: UserDocument, classId: string) {
        console.log('check if user has class: %s', classId)
        if (!userDoc.purchasedClasses) { 
            console.log('could not find purchasedClasses element. thus user has no purchased classes...')
            return false}
        
        if (Object.keys(userDoc.purchasedClasses).indexOf(classId) == -1) {
            console.log('user has purchased classes array, but this class was not found')
            return false
        }

        return true
    }

    static classIsFinished (userDoc: UserDocument, masterclass: Masterclass) {
        if (!masterclass.modules) { return }

        let classIsFinished = true

        const moduleIds = Object.keys(masterclass.modules)

        for (let index = 0; index < moduleIds.length; index++) {
            const moduleId = moduleIds[index];
            if (this.moduleIsFinished(userDoc, moduleId) == false) {
                classIsFinished = false;
                break
            }
        }
            
        return classIsFinished
    }

    static startModule(moduleId: string) {
        var startModule: Record<string, UserModuleDocument> = {}
        startModule[moduleId] = {
            status: Constants.MODULE_STARTED,
            progress: 0
        }

        FireService.updateUser({
            modules: startModule
        })
    }

    static finishedModule(moduleId: string) {
        var finishedModule: Record<string, any> = {}
        finishedModule[moduleId] = { status: Constants.MODULE_FINISHED }

        FireService.updateUser({
            modules: finishedModule
        })
    }
    /**
     * Whenever the user is logged out, we need to delete the ipAddress field from the document, this makes sure that the
     * user can log in from another ip address without being booted out
     * */
    static removeIpAddress() {
        return FireService.updateUser(
            { ipAddress: firebase.firestore.FieldValue.delete() }
        )
    }

    /**
     * Prevents user from logging into site across different devices, to protect from credential sharing
     * 
     * Gets the current ip address of the device and checks if it's equal to the last ip address when logging in
     * If the ip address is different, then we automatically sign the user out
     * 
     * You must handle what you want to happen with the result yourself, this function does not change state (has no side affects)
     * 
     * @param userId The id of the user to check if the ip is valid
     * @returns Returns a Promise of of type { isValid: boolean, ipAddress:string }.  isValid is true if the ip is valid, false if not.  The ipAddress
     * is the current ipAddress
     */
    static checkIfIpIsValid = (userId: string): Promise<{ isValid: boolean, ipAddress: string }> => {

        return new Promise((resolve, reject) => {
            fetch('https://api.ipify.org?format=json').then(response => {
                return response.json();
            }).then((res: any) => {
                const ipAddress = res.ip

                var db = firebase.firestore()
                db.collection(Constants.USERS_COLLECTION).doc(userId).get().then(snapshot => {
                    const user = snapshot.data()

                    if (!user) { return }

                    if (user.ipAddress && user.ipAddress != ipAddress) {                        
                        resolve({ isValid: false, ipAddress: ipAddress })                        
                        return
                    }

                    FireService.updateUser({ ipAddress: ipAddress })
                    resolve({ isValid: true, ipAddress: ipAddress })
                })

            }).catch((err: any) => reject('Problem fetching my IP' + err))
        })


    }

    static updateUser(update: any) {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    var db = firebase.firestore()
                    db.collection(Constants.USERS_COLLECTION).doc(user.uid).set(update, { merge: true }).then(result => {
                        resolve(result)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        })
    }

    static async getUser(userId: string) {
        const user = await firebase.firestore().collection(Constants.USERS_COLLECTION).doc(userId).get()
        const userData = user.data()
        console.log("loaded user with id %s. see user data below...", userId)
        console.dir(userData)
        return userData
    }

    static initializeRemoteConfig () {

    }

    static initializeFirebaseApp() {
        if (firebase.apps.length === 0) {
            firebase.initializeApp({
                apiKey: 'AIzaSyD-CSJMQgbfenyaUPKIRJCxoRwvfHWju2E',
                authDomain: 'pmu-masterclasses.firebaseapp.com',
                projectId: 'pmu-masterclasses',
                storageBucket: 'pmu-masterclasses.appspot.com',
                appId: "1:926404596577:web:0bf4fa8ed46da12f586b20"
            })

            const remoteConfig = firebase.remoteConfig()
            remoteConfig.settings = {
                fetchTimeoutMillis: 300000,
                minimumFetchIntervalMillis: 43200000
            };

            remoteConfig.fetchAndActivate()
        }
    }
}

export default FireService