import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import Plus from '../../assets/plus.png'
import SwitchControl from '../switch-control/switch-control'
import AddImageInput from '../addImage/addImageInput'
import Utils from '../../utils/images'

const AddImage = (props: {
    ref: any,
    imageUrl?: string,
    buttonCaption?: string,
    onImagesAdded: (images: File[]) => Promise<void>
}) => {

    const imageRef = useRef<HTMLDivElement>(null)
    const [selectedImageStyle, setSelectedImageStyle] = useState({ display: 'none' })
    const [imageRotation, setImageRotation] = useState(0)
    const [imageUrl, setImageUrl] = useState(props.imageUrl ?? "")

    useEffect(() => {
        if (imageUrl != "") {
            updateAddImageStyleToViewSelectedImage()
            displayCurrentImage()            
        }
    }, [imageUrl])

    const addImageStyleInitialState = {
        backgroundColor: '#efefef',
        borderRadius: '10px'
    }

    const [addImageStyle, setAddImageStyle] = useState(addImageStyleInitialState)

    const dashedBorder = {
        position: "absolute" as "absolute",
        width: "90%",
        height: "280px",
        borderStyle: "dotted",
        top: "10px",
        borderColor: 'grey'
    }

    const getImageSelectedStyleObject = () => {
        return {
            position: "absolute" as "absolute",
            width: "100%",
            height: "100%",
            borderRadius: '10px',
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'block',
            zIndex: 1
        }
    }

    const onImagesAdded = (evt: any) => {
        const files = evt.target.files
        const utils = new Utils()
        
        setImageRotation(0)
        if (props.onImagesAdded) {
            const array = utils.fileListToArray(files)
            props.onImagesAdded(array)
        }

        if (files.length > 0 && imageRef.current) {
            setImageUrl(URL.createObjectURL(files[0]))

            updateAddImageStyleToViewSelectedImage()  
            displayCurrentImage()
        }
    }

    const updateAddImageStyleToViewSelectedImage = () => {
        setAddImageStyle(prevState => {
            return ({
                ...prevState,
                backgroundColor: 'white'
            })
        })
    }

    const displayCurrentImage = () => {
        setSelectedImageStyle(getImageSelectedStyleObject())
    }

    /** When the user presses the delete button to remove the image that they just uploaded */
    const deleteButtonPressed = () => {

        setAddImageStyle(addImageStyleInitialState)

        setSelectedImageStyle(prevState => {
            return {
                ...prevState,
                display: 'none'
            }
        })
    }

    const updateImageRotate = () => {
        let imageStyle = selectedImageStyle as any
        let newImageRotation = imageRotation + 90
        setImageRotation(newImageRotation)
        setSelectedImageStyle((previousImageStyle) => {
            return {
                ...previousImageStyle,
                transform: `rotate(${newImageRotation}deg)`
            }
        })
    }

    return (
        <div id="addImageWrapper" ref={imageRef}>
            <Col xs="auto" style={addImageStyle}>
                <Container>
                    <Row className="justify-content-center">
                        <div style={selectedImageStyle}>

                        </div>
                        {
                            selectedImageStyle.display == 'none' &&
                            <div style={dashedBorder}>

                            </div>
                        }
                        <Col xs="12" style={{ height: '100px' }}>

                        </Col>

                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto" style={{ height: '100px' }}>
                            <AddImageInput caption={props.buttonCaption} style={{ width: "150px" }} onChange={onImagesAdded} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto" style={{ height: '100px' }}>
                            <div style={{ width: '200px', textAlign: 'center' }}>
                                Click add image and then select the image to use
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col xs="12">
                {
                    selectedImageStyle.display != 'none' &&
                    <SwitchControl
                        buttonTitles={['Delete']}
                        variants={['dark', 'dark']}
                        buttonFunctions={[deleteButtonPressed, updateImageRotate]} />
                }

            </Col>
        </div>

    )
}

export default AddImage