import React from 'react'
import { Image, Container, Row, Col } from 'react-bootstrap'
import SaveButton from '../components/saveButton'
import { useHistory } from 'react-router-dom'

const Submitted = () => {

    const history = useHistory()

    const goToDashboard = () => {
        history.push('/')
    }

    return (
        <Container>
            <Row className="text-center">
                <Col xs="12" className="mt-5">
                    <Image src="/success.png" height="100" width="100" />
                </Col>
                <Col xs="12" className="mt-5">
                    <h1>
                        Submitted for Review
                    </h1>
                </Col>
                <Col xs="12" className="mt-3">
                    Your class has been submitted for review!
                </Col>
                <Col xs="12">
                    We’ll let you know ASAP when it’s accepted and made live.  In the meantime, start letting others know about the class you’ve created.
                </Col>
                <SaveButton title="Go to Dashboard" loading={false} action={goToDashboard} ></SaveButton>
            </Row>
        </Container>
    )
}

export default Submitted