import React, { useState, useEffect } from 'react'
import { Constants } from '../objects/constants'
import firebase, { User } from 'firebase'
import { Question, Reply } from '../objects/questions'
import { Row, Col, Button, Container } from 'react-bootstrap'
import SimpleTextArea from './form/textArea'
import SimpleInput from './form/input';
import { v4 as uuidv4 } from 'uuid'
import { userInfo } from 'os'
import Username from './user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import Utils from '../utils/images'
import { ClassModule, Masterclass } from '../objects/masterclass'

const Discussion = (props: { currentTime: number, masterclass: Masterclass, module: ClassModule }) => {

    const [question, setQuestion] = useState<string>("")

    /** The currently selected question */
    const [selectedQuestion, setSelectedQuestion] = useState<Question>()

    /** List of all the questions that have been asked */
    const [questions, setQuestions] = useState<Question[]>()

    /** The id of the question that the user is replying to */
    const [replyQuestionId, setReplyQuestionId] = useState("")

    /** If the user is replying to a question then this stores the reply */
    const [reply, setReply] = useState("")

    /** The current user */
    const [user, setUser] = useState<User>()

    /** Whether the current user is asking a question */
    const [askingQuestion, setAskingQuestion] = useState(false)

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            setUser(user)
        }
    })

    useEffect(() => {
        getQuestions()
    }, [])

    const submitQuestion = () => {

        var db = firebase.firestore()

        if (!question || !user) { return }

        // If the masterclass doesn't have an id that's a very big problem, this should never happen though
        if (!props.masterclass.id) { return }

        const questionObject: Question = {
            title: question,
            id: uuidv4().substring(0, 8),
            moduleId: props.module.id,
            classId: props.masterclass.id,
            time: props.currentTime,
            answered: false,
            userId: user.uid,
            replies: []
        }

        db.collection(Constants.QUESTIONS_COLLECTION).doc(questionObject.id).set(questionObject)

        setQuestion("")
        setAskingQuestion(false)

        if (!props.masterclass.trainer?.emails) { return }

        sendNotificationToEmails(props.masterclass.trainer.emails)
    }

    const sendNotificationToEmails = (emails: string[]) => {

        if (!props.masterclass.basicInfo) { return }

        emails.map(email => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    toEmail: email,
                    subject: `Someone asked a question for your class ${props.masterclass.basicInfo?.name} `,
                    body: 
`A user has just asked a question in your class. Below is the information you need to answer the question.

Class Name: ${props.masterclass.basicInfo?.name}
Module Name: ${props.module.name}
Question Asked: ${question}
Time in Video Question Asked: ${props.currentTime}
                    
Here are the steps you need to take to answer the question.

1. Click the following link to be taken to the module - https://learn.pmumasterclasses.com/module/${props.module.id}/${props.masterclass.id}
2. Find the question in the Previous Questions section of the Discussion at the bottom of the screen. (You may have to scroll down)
3. Once you find the question, click the Reply button 
4. Enter your reply and click the Submit button
5. And you're done

If you have any questions email us at info@pmumasterclasses.com`
                })
            };

            fetch(`${Utils.baseUrl()}/sendEmail`, requestOptions)
                .then(response => response.json())
                .then(response => console.log(response))
                .catch(error => console.error(error))

        })
    }

    const getQuestions = () => {

        var db = firebase.firestore()

        db.collection(Constants.QUESTIONS_COLLECTION)
            .where('moduleId', '==', props.module.id)
            .where('classId', '==', props.masterclass.id)
            .onSnapshot(snapshot => {
                setQuestions(snapshot.docs.map(doc => doc.data() as Question))
            })
    }

    const submitReply = () => {
        var db = firebase.firestore()

        if (!user) { return }

        const replyObject: Reply = {
            id: uuidv4().substring(0, 8),
            userId: user.uid,
            reply: reply
        }

        db.collection(Constants.QUESTIONS_COLLECTION).doc(replyQuestionId)
            .update({
                replies: firebase.firestore.FieldValue.arrayUnion(replyObject)
            }).then(_ => {
                setReplyQuestionId("")
                setReply("")
            })
    }

    return (
        <div className="mb-5">

            {
                !askingQuestion &&
                <Row className="mb-5">
                    <Col xs="12">
                        <Button className="rounded-pill" onClick={() => setAskingQuestion(true)} style={{ backgroundColor: Constants.Purple, border: "none" }}>Ask New Question</Button>
                    </Col>
                </Row>
            }

            {
                askingQuestion &&
                <Row style={{ backgroundColor: Constants.LightGray }}>
                    <Col xs="12" className="mt-2">
                        <SimpleInput
                            value={question ?? ""}
                            headerName={`Your Question asked at ${Utils.getCurrentTime(props.currentTime)}`}
                            placeholder="Write your question for this video"
                            header={false}
                            onChange={setQuestion} />
                    </Col>
                    <Col xs="12" className="mt-3 mb-3">
                        <Button className="rounded-pill" onClick={submitQuestion} style={{ backgroundColor: Constants.Green, border: "none" }}>Submit</Button>
                        <Button className="rounded-pill ml-3" onClick={() => setAskingQuestion(false)} style={{ backgroundColor: "red", border: "none" }}>Cancel</Button>
                    </Col>
                </Row>
            }


            <Row className="mt-5">
                <Col style={{ color: Constants.Purple }}>
                    <h2>Previous Questions</h2>
                    <hr />
                </Col>
            </Row>
            {
                questions &&
                questions.map(question =>
                    <div>
                        <Row>
                            <Col xs="10" className="mt-3">
                                <span style={{ borderRadius: "25px" }}><strong>Q: {question.title}</strong> </span>
                                <Button className="rounded-pill ml-2" onClick={() => setReplyQuestionId(question.id)} style={{ backgroundColor: "white", color: "blue", border: "none" }}>
                                    <FontAwesomeIcon icon={faReply} /> Reply
                                </Button>
                                <div style={{ color: Constants.TextGray }}><strong>asked by <Username id={question.userId} /></strong> at time <strong>{Utils.getCurrentTime(question.time)}</strong> in video</div>
                            </Col>
                            {
                                !question.answered &&
                                <Col xs="2" className="mt-4">

                                </Col>
                            }
                        </Row>
                        {
                            replyQuestionId == question.id &&
                            <Row style={{ backgroundColor: Constants.LightGray }} className="mt-4">
                                <Col xs="12">
                                    <SimpleInput
                                        value={reply ?? ""}
                                        placeholder="Enter your reply"
                                        headerName="Your Reply"
                                        header={false}
                                        onChange={setReply} />
                                </Col>
                                <Col xs="12" className="mt-3 mb-3">
                                    <Button className="rounded-pill" onClick={submitReply} style={{ backgroundColor: Constants.Green, border: "none" }}>Submit</Button>
                                    <Button className="rounded-pill ml-3" onClick={() => setReplyQuestionId("")} style={{ backgroundColor: "red", border: "none" }}>Cancel</Button>
                                </Col>
                            </Row>

                        }

                        {
                            question.replies &&
                            question.replies.map(reply =>
                                <Row className="mt-2 ml-1">
                                    <Col xs="12" className="mt-2">
                                        <strong><Username id={user?.uid} /></strong> <span style={{ borderRadius: "25px" }}>{reply.reply}</span>
                                    </Col>
                                </Row>
                            )
                        }

                    </div>
                )
            }
        </div >
    )
}

export default Discussion