import React, { useEffect, Component, useState } from 'react'
import Utils from '../../utils/images'
import firebase from 'firebase'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import './authGuard.css'
import FireService from '../../firebaseService'
import { useAuth0 } from '@auth0/auth0-react'
import { Constants } from '../../objects/constants'

const AuthGuard = (props: { component: JSX.Element }) => {

    const [loading, setLoading] = useState(true)

    const [authenticated, setAuthenticated] = useState(false)

    const { isAuthenticated }  = useAuth0()

    const history = useHistory()

    const { loginWithRedirect } = useAuth0()

    FireService.initializeFirebaseApp()

    useEffect(() => {
        
        const authType = new URLSearchParams(history.location.search).get('authType')
        
        if (authType == 'auth0') {
            localStorage.setItem(Constants.REDIRECT_URL, window.location.pathname)
            loginWithRedirect()
        }
                
        firebase.auth().onAuthStateChanged((user) => {                        
            if (!user) {                
                localStorage.setItem(Constants.REDIRECT_URL, window.location.pathname)
                history.replace(`/signin?redirectUrl=${window.location.pathname}`)                
            } else {
                setAuthenticated(true)
                setLoading(false)
            }
        })
    }, [])

    useEffect(() => {
        console.log("isAuthenticated = ", isAuthenticated)
    }, [isAuthenticated])

    return (
        <div>
            {
                loading == false && authenticated &&
                props.component
            }
            {
                loading &&
                <div className="loading" style={{ height: "100%" }} >
                    <div className="justify-content-center align-items-center">
                        <Image className="loading" src="/30.gif" />
                    </div>
                </div>
            }
        </div>
    )
}

export default AuthGuard