import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'

const AddImageInput = (props:any) => {

    const fileInputRef = useRef(null)

    /**
     * When the user clicks the add image button
     */
    const addImageClicked = () => {
        
        const fileInput = fileInputRef.current as any
        if (fileInput) {
            fileInput.value = ""
            fileInput.click()
        }
    }

    return (
        <div>
            <Button onClick={addImageClicked} className="rounded-pill" style={{backgroundColor: "#8e2d8e", border: "none"}} >{props.caption ?? 'Add Image(s)'}</Button>
            <input
                style={{ display: 'none' }} // Don't show this input
                ref={fileInputRef}
                className="FileInput"
                type="file"
                multiple
                onChange={props.onChange} />
        </div>
    )

}

export default AddImageInput