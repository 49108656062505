import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Container, InputGroup, Button, FormControl } from 'react-bootstrap'
import Utils from '../utils/images'

const Coupon = (props: {
    setCouponCode: any,
    setDiscount: any,
    buttonColor: string,
    classId: string
}) => {

    const [couponCode, setCouponCode] = useState("")

    const [error, setError] = useState("")

    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        setError("")
    }, [couponCode])

    const validateCouponCode = (couponCode: string) => {
        setProcessing(true)
        setError("")

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        if (couponCode.trim().length == 0) {
            setError("No discount code entered.  To get your discount, enter a discount code above and then press 'Apply Discount Code'")
            setProcessing(false)
            return
        }

        fetch(`${Utils.baseUrl()}/couponCodeById?code=${couponCode.toUpperCase()}&classId=${props.classId}`, requestOptions)
            .then(response => response.json())
            .then(response => response)
            .then(coupon => {
                setProcessing(false)

                if (!coupon) {
                    return
                }

                console.log(coupon)

                props.setCouponCode(coupon.concatedCode)
                props.setDiscount(coupon.savings)
            })
            .catch(error => {
                setProcessing(false)
                setError("Sorry! This coupon code is invalid or expired. 😭 Please make sure the coupon code you have is not expired and that you typed in the code correctly.  Or email: info@pmumasterclasses.com. Thanks!")
            })
    }

    return (
        <div>
            <Row className="mb-4">
                <Col>
                    <InputGroup>
                        <FormControl
                            aria-describedby="basic-addon1"
                            placeholder="Enter coupon code"
                            onChange={(event: any) => setCouponCode(event.target.value)}
                        />
                        <InputGroup.Append>
                            <Button style={{ backgroundColor: props.buttonColor, border: "none" }} variant="primary" onClick={() => validateCouponCode(couponCode)}> {processing == true ? 'Processing...' : 'Apply Discount Code'} </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                {
                    error &&
                    <Col xs="12" className="mb-3" style={{ color: "red" }}>
                        {error}
                    </Col>
                }
            </Row>

        </div >

    )
}



export default Coupon