import React from 'react'
import { Image } from 'react-bootstrap'

const Success = () => {
    return (
        <div className="loading" style={{ height: "100%" }} >
            <div className="justify-content-center align-items-center">
                <Image className="loading" src="/30.gif" />
            </div>
        </div>
    )
}

export default Success