import React, { useEffect, useState } from 'react'
import { Image, Container, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'
import { Constants } from '../objects/constants'
import StripeUtils from '../components/stripe-utils'
import Utils from '../utils/images'

const PaymentSuccess = () => {
    
    const history = useHistory()

    const [classId, setClassId] = useState("")

    const goToClass = () => {
        history.push(`/class/${classId}/masterclass/true`)
    }

    const goToDashboard = () => {
        history.push('/')
    }

    const incrementTrancheSellCount = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({                
                "classId": classId
            })
        };
        
        fetch(`${Utils.baseUrl()}/incrementTrancheSellCount`, requestOptions)
            .then(response => response.json())
            .then(response => {
                console.log(response)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        incrementTrancheSellCount()
    }, [classId])

    useEffect(() => {
        
        const db = firebase.firestore()        

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                // Get the user document from the server
                let userRef = db.collection(Constants.USERS_COLLECTION).doc(user.uid)

                userRef.get().then(userObject => {
                    const userData = userObject.data()

                    if (!userData) { return }
                    if (!userData.stripeSession) { return }

                    const masterclassId = userData.stripeSession.id
                    setClassId(masterclassId)

                    if (masterclassId) {                                                
                        var purchase: Record<string, boolean> = userObject?.data()?.purchasedClasses ?? {}

                        purchase[masterclassId] = true                        

                        userRef.update({
                            purchasedClasses: purchase,                            
                        })
                        
                        StripeUtils.removeSession()
                    }
                })
            }
        })
    }, [])


    return (
        <Container>
            <Row className="text-center">
                <Col xs="12" className="mt-5">
                    <Image src="/success.png" height="100" width="100" />
                </Col>
                <Col xs="12" className="mt-5">
                    <h1>
                        Thanks for purchasing!
                    </h1>
                </Col>
                <Col xs="12" className="mt-3">
                    You can now start attending your class.
                </Col>
                <Col xs="12">
                    To see your new class, click the 'Go To Dashboard' button below and scroll down to 'Your Purchased Classes'
                </Col>
                <Col xs="12" className="mt-3">
                    <Button title="Go to Class" style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={goToClass} >Go To Class</Button>
                    <Button className="ml-3" title="Go to Dashboard" variant="info" onClick={ goToDashboard } >Go To Dashboard</Button>
                </Col>

                

            </Row>
        </Container>
    )
}

export default PaymentSuccess