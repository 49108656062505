import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SimpleInput from '../../form/input';
import SimpleTextArea from '../../form/textArea';
import AddImage from '../../form/addImage'
import { Trainer } from '../../../objects/masterclass';
import SaveButton from '../../saveButton';
import { Constants } from '../../../objects/constants';
import firebase from 'firebase';
import Utils from '../../../utils/images';

const TrainerInformation = (props: { trainer?: Trainer, classId?: string }) => {

    /** Name of the trainer for this class */
    const [trainerName, setTrainerName] = useState(props.trainer?.name ?? "")
    /** Name of the academy for this class or that this trainer is a part of */
    const [academyName, setAcademyName] = useState( props.trainer?.academyName ?? "")
    /**  */
    const [bio, setBio] = useState( props.trainer?.bio ?? "")

    /** Emails to send notifications to for this trainer. Should be seperated by commas */
    const [emails, setEmails] = useState("")

    const [trainer, setTrainer] = useState(props.trainer)

    const imageRef = useRef<JSX.Element>(null)

    const [profileImage, setProfileImage] = useState<File | Blob>()

    /** URL of the image selected by the user  */
    const [imageFileUrl, setImageFileUrl] = useState<string | undefined>(trainer?.imageUrl)

    const [loading, setLoading] = useState(false)

    const inputHeader = {
        color: "#575757"
    }

    useEffect(() => {
        setTrainer({
            academyName: academyName,
            bio: bio,
            name: trainerName,
            imageUrl: imageFileUrl ?? "",
            emails: emails.split(',')
        })

    }, [trainerName, academyName, bio, imageFileUrl, emails])

    const saveTrainerInformation = async () => {
        if (!props.classId || !trainer) {
            return
        }

        const db = firebase.firestore()
        var imageRef = firebase.storage().ref().child(`masterclasses/${props.classId!}/profile.jpg`)

        setLoading(true)

        if (!profileImage) {
            setLoading(false)
            await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({ trainer: trainer })
            return
        }

        imageRef.put(profileImage).then(async snapshot => {
            let downloadUrl = await snapshot.ref.getDownloadURL()
            setImageFileUrl(downloadUrl)
            let trainerToUpload = trainer
            trainerToUpload.imageUrl = downloadUrl
            await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({ trainer: trainerToUpload })
            setLoading(false)
        }).catch(error => {
            console.log("Error saving image ", error)
        })
    }

    // When the image has been selected, than we compress it and save it as a state object
    const onImagesAdded = async (images: File[]) => {
        const utils = new Utils()

        if (images.length == 0) {
            return
        }

        // Compress the image
        const compressedImage = await utils.compressImage(images[0])

        if (!compressedImage) { return }

        setImageFileUrl(URL.createObjectURL(compressedImage))
        setProfileImage(compressedImage)

    }

    return (
        <Container>

            <Row>
                <Col xs="12" md="5">
                    <AddImage imageUrl={props.trainer?.imageUrl} onImagesAdded={onImagesAdded} ref={imageRef} />
                </Col>
                <Col xs="12" md="7">
                    <SimpleInput
                        value={props.trainer?.name ?? ""}
                        placeholder="Who is the trainer who will teach this class?"
                        headerName="Trainer Name"
                        header={false}
                        onChange={setTrainerName} />

                    <SimpleInput
                        value={props.trainer?.academyName ?? ""}
                        placeholder="What's the name of the academy responsible for this class?"
                        headerName="Academy name (optional)"
                        header={false}
                        onChange={setAcademyName} />

                    <SimpleInput
                        value={props.trainer?.emails?.join() ?? ""}
                        placeholder="What email do you want notifications sent to?"
                        headerName="Email"
                        header={false}
                        onChange={setEmails} />

                    <SimpleTextArea
                        value={props.trainer?.bio ?? ""}                    
                        headerName="Bio"
                        placeholder="Write some information about the trainer or the academy?"
                        header={false}
                        onChange={setBio} />

                    <SaveButton loading={loading} action={saveTrainerInformation} title="Save Trainer Information" />
                </Col>
            </Row>

        </Container>
    )

}

export default TrainerInformation