import React, { useEffect, useState } from 'react'
import { Container, Row, Col, CardColumns } from 'react-bootstrap'
import firebase from 'firebase'
import FireService from '../firebaseService'
import SimpleInput from '../components/form/input'
import { Constants } from '../objects/constants'
import { Masterclass } from '../objects/masterclass'
import ClassElement from '../components/classelement'
import { stringify } from 'querystring'
import Loading from '../components/loading'

const GiveClass = () => {

    const [authorized, setAuthorized] = useState(false)

    const [classes, setClasses] = useState<Masterclass[]>()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setAuthorized(FireService.userIsAdmin(user.uid))
                getAllClasses()
            }
        })


    }, [])

    const getAllClasses = async () => {
        const db = firebase.firestore()
        let classes = (await db.collection(Constants.CLASS_COLLECTION).where('status', '==', 'live').get()).docs.map(doc => doc.data())
        setClasses(classes)
    }

    const revokingClass = async (classId: string) => {
        const db = firebase.firestore()
        setLoading(true)

        db.collection(Constants.USERS_COLLECTION).get().then(response => {
            let batch = firebase.firestore().batch()

            if (response.docs.length == 0) {
                setLoading(false)
            }

            response.docs.map(async doc => {
                const docRef = firebase.firestore().collection(Constants.USERS_COLLECTION).doc(doc.id)
                const updateDoc: Record<string, any> = {}
                updateDoc[`purchasedClasses.${classId}`] = firebase.firestore.FieldValue.delete()
                batch.update(docRef, updateDoc)
            })

            batch.commit().then(() => {
                setLoading(false)
            })
        })
    }

    return (
        <Container>
            {
                authorized &&
                <Row>
                    <Col xs="12">
                        <div className="jumbotron mt-4">
                            <h1 className="display-4">Give away a class!</h1>
                            <p className="lead"></p>
                            <hr className="my-4" />
                        </div>
                    </Col>
                    <Col xs="12">
                        <h3>Which class would you like to give away?</h3>
                    </Col>
                    <Col>
                        <CardColumns>
                            {
                                classes &&
                                classes.map(masterclass => {
                                    return (
                                        <Col xs="12">
                                            <Loading component={<ClassElement masterclass={masterclass} revoking={true} revokingClass={revokingClass} />} isLoading={loading} />
                                        </Col>
                                    )
                                })
                            }
                        </CardColumns>
                    </Col>
                </Row>
            }

        </Container>
    )
}

export default GiveClass