import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="thepmucon.us.auth0.com"
        clientId="1Y3sSQXm4X6n67xf8tkr0VbhZ4wMdK7r"
        redirectUri={`${window.location.origin}/success`}
        audience="https://thepmucon.us.auth0.com/api/v2/"
        scope="read:current_user update:current_user_metadata"
        useRefreshTokens={true}
        cacheLocation="localstorage"
        leeway={10}
        authorizeTimeoutInSeconds={10}      
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode >,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
