import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'

const SaveButton = (props: {
    loading: boolean,
    title: string,
    action?: () => void
}) => {

    return (
        <Container>
            <Row className="my-3">
                <Col>
                    {
                        props.loading &&
                        <Image src="/30.gif" />
                    }
                    {
                        !props.loading &&
                        <Button onClick={props.action} style={{
                            backgroundColor: "#2cb86b",
                            border: "none",
                            borderRadius: "25px"
                        }}>{props.title}</Button>
                    }

                </Col>
            </Row>
        </Container>
    )
}

export default SaveButton