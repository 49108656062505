import React, { useEffect, useState, useRef, LegacyRef, MutableRefObject } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import firebase from 'firebase'
import { Constants } from '../objects/constants'
import { Masterclass, ClassModule } from '../objects/masterclass'
import { Container, Col, Row, Button, Image, Modal, ModalBody } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFastForward, faFastBackward, faPause, faPlay, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import Discussion from '../components/discussion'
import FireService from '../firebaseService'
import { UserDocument } from '../objects/userDocument'
import Finished from '../components/finished'
import { FullScreen, useFullScreenHandle, FullScreenHandle } from 'react-full-screen'
import Utils from '../utils/images'
import { useAuth0 } from '@auth0/auth0-react'
import { isCompositeComponent } from 'react-dom/test-utils'

const TakeModule = (props: { userDoc: UserDocument }) => {

    /** The id of the module that will be displayed on this page.  Every class is made up of multiple modules. */
    const { moduleId } = useParams()

    /** The id of the class that this module is for */
    const { classId } = useParams()

    /** The class that is being viewed on this page.  This can be retrieved by pulling from the database use the classId property */
    const [masterclass, setMasterclass] = useState<Masterclass>({})

    /** The url of the video to play */
    const [videoUrl, setVideoUrl] = useState<string>()

    /** A reference to the ReactPlayer so that we can control it within this page */
    const ref = useRef<ReactPlayer>(null)

    /** The furthest position that the user has viewed this video.  They cannot fast forward past this point. */
    const [farthestPosition, setFarthestPosition] = useState<number>(0)

    /** Controls whether or not the video is playing.  If set to true, the video player will start playing */
    const [playing, setPlaying] = useState(false)

    /** The actual class module that is being viewed on this page.  Can be retrieved from Firebase by using the moduleId property on this component */
    const [module, setModule] = useState<ClassModule>()

    /** Whether or not to show a prompt asking the user whether or not to rate. */
    const [showRating, setShowRating] = useState(false)

    /** A boolean value indicating whether or not the video has been loaded yet */
    const [videoLoaded, setVideoLoaded] = useState(false)

    /** 
     * Responsible for setting various elements, in our case the video and it's controls, to full screen or normal mode
     * For more information see: https://openbase.io/js/react-full-screen/documentation
     */
    const handle = useFullScreenHandle()

    /** Boolean value indicating that the video is currently loading */
    const [loading, setLoading] = useState(false)

    /** The amount of time left in the video as a string value of hh:mm:ss format */
    const [timeLeft, setTimeLeft] = useState("")

    /** The current progress of the video in 0 hr 0 min 0 sec format */
    const [currentTime, setCurrentTime] = useState("")

    /** The current progress of the video in seconds */
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0)

    /** These properties/functions are responsible for Auth0 functionality
     * isAuthenticated - whether the user is authenticated using Auth0
     * getAccessTokenSilently - gets the AccessToken for the most current user, if there is no valid token then it calls Auth0 and tries
     * to get a valid one, if it cannot an error is thrown. ** An error means that the user has been revoked **
     */
    const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0()

    /** The dimensions of the video.  We set this at different times to mimick responsiveness */
    const [videoDimensions, setVideoDimensions] = useState({
        height: "500px",
        width: window.innerWidth < 500 ? "95%" : "800px"
    })

    /** Whether or not the video should be full screen */
    const [fullScreen, setFullScreen] = useState(false)

    /** When the user presses rewind or fast forward, this is how many seconds we rewind or fast forward by */
    const navigationTimeInSeconds = 10

    /**
     * Toggles full screen on or off
     * @param state The current state of the video
     * @param handle The handle responsible for determining whether to display in fullscreen or not
     */
    const fullscreenToggled = (state: boolean, handle: FullScreenHandle) => {
        if (handle.active) {
            setFullScreen(true)
            setVideoDimensions({
                height: "100%",
                width: "100%"
            })
            return
        }

        setFullScreen(false)

        setVideoDimensions({
            height: "500px",
            width: window.innerWidth < 500 ? "95%" : "800px"
        })
    }

    /**
     * Gets a string representation of time
     * @returns String representation of time like 3 hrs and 40 minutes
     * @param timeInMinutes 
     */
    const getTime = (timeInMinutes: number) => {

        const hours = Math.floor(timeInMinutes / 60)
        const minutes = Math.floor(timeInMinutes % 60) + 1

        if (timeInMinutes < 1) {
            return `${Math.ceil(60 * timeInMinutes)} seconds`
        }

        if (timeInMinutes < 60) { return `About ${Math.floor(timeInMinutes)} mins` }

        return `About ${hours} hrs and ${minutes} mins`

    }

    useEffect(() => {
        var db = firebase.firestore()

        db.collection(Constants.CLASS_COLLECTION).doc(classId).get().then(snapshot => {
            const masterclass = snapshot.data()
            if (!masterclass) { return }

            setMasterclass(masterclass)
            setModule(masterclass.modules[moduleId])
            setVideoUrl(masterclass.modules[moduleId].videoUrl)
        })

        if (iOS()) {
            setPlaying(true)
        } else {
            setVideoLoaded(true)
        }

    }, [])

    /**
     * Rewind the video by whatever the navigationTimeInSeconds value is set to
     */
    const rewind = () => {
        if (!ref.current) { return }

        const currentPosition = ref.current.getCurrentTime()

        const timeToSeekTo = currentPosition - navigationTimeInSeconds > 0 ? currentPosition - navigationTimeInSeconds : 0

        ref.current.seekTo(timeToSeekTo)
    }

    /**
     * Fast forward the video by whatever the navigationTimeInSeconds value is set to
     */
    const fastForward = () => {
        if (!ref.current) { return }

        const currentTime = ref.current.getCurrentTime()

        if (currentTime + navigationTimeInSeconds < farthestPosition) {
            ref.current.seekTo(currentTime + navigationTimeInSeconds)
        } else {
            ref.current.seekTo(farthestPosition)
        }
    }

    /**
     * Update the current progress state and update on the server as well
     * @param seconds The current progress of the video in seconds
     */
    const updateFarthestPosition = (seconds: number) => {
        // Set the farthest position so that we have access to this for things like video commenting
        setFarthestPosition(seconds)

        // If showControls is undefined that means that we are going to show the controls
        if (module?.showControls == undefined) { return }

        // Only update the progress if the module is not finished
        if (!FireService.moduleIsFinished(props.userDoc, moduleId)) {
            FireService.setFarthestPosition(moduleId, seconds)
        }
    }

    const onProgress = (state: any) => {
        if (farthestPosition < state.playedSeconds) {
            updateFarthestPosition(state.playedSeconds)
        }
        if (!ref.current) { return }
        const currentTime = ref.current.getCurrentTime()
        const progress = FireService.progressForModule(props.userDoc, moduleId)
        const greatestTime = currentTime > progress ? currentTime : progress

        const formattedTimeLeft = getTime((ref.current.getDuration() - greatestTime) / 60)

        setTimeLeft(`${formattedTimeLeft} left in video`)

        setCurrentTime(Utils.getCurrentTime(currentTime))

        setCurrentTimeInSeconds(currentTime)

        // Check every five minutes whether the user is valid
        if (Math.round(currentTime) % 300 == 0) {
            checkIfAuthenticated()
        }
    }

    /**
     * If the user was authenticated using Auth0 we need to make sure that their access token is still valid
     * If it isn't we need to remove log them out immediately so that they're not allowed to watch any videos
     * 
     * We also need to make sure that if they've signed in with Firebase we do the same thing, kick them out if they're
     * not logged in
     */
    const checkIfAuthenticated = async () => {

        if (FireService.userIsAdmin(props.userDoc.id)) { return }

        const isBanned = await FireService.isBanned(props.userDoc.id, logout)

        // If the user is banned they've already been signed out so no need to move forward
        if (isBanned) { return }

        let ipIsValid = await FireService.checkIfIpIsValid(props.userDoc.id)

        // If the ip address is invalid, then log the user out
        if (ipIsValid.isValid == false) {
            FireService.logout(logout)
            return
        }
    }

    /** Check to see if the user has finished not just this module but the entire class */
    const isClassFinished = () => {
        if (!masterclass || !masterclass.modules) { return false }

        if (Object.keys(masterclass.modules).length == 1) {
            return true
        }

        const modulesKeyValPair = props.userDoc.modules
        if (!modulesKeyValPair) {
            return false
        }

        const modules = Object.keys(props.userDoc.modules).map(moduleId => props.userDoc.modules[moduleId])
        const finishedModules = modules.filter(module => module.status == Constants.MODULE_FINISHED)
        if (finishedModules.length + 1 >= Object.keys(masterclass.modules).length) {
            return true
        }

        return false
    }

    /**
     * Called when the video has reached the end
     */
    const onEnded = () => {
        isClassFinished()

        if (FireService.moduleIsFinished(props.userDoc, moduleId) == false) {
            FireService.finishedModule(moduleId)
        }

        if (FireService.userIsAdmin(props.userDoc.id) == false) {
            setShowRating(true)
        }
    }

    /** User has started playing the video */
    const onPlay = () => {
        setPlaying(true)
    }

    /** User has paused the video */
    const onPause = () => {
        setPlaying(false)
        setVideoLoaded(true)
    }

    /** Start playing the video */
    const play = () => {
        if (!ref.current) { return }
        setPlaying(!playing)
    }

    /** Get the Id of the next module for this class, if there is one */
    const getNextModuleId = () => {
        if (!masterclass.modules) { return }
        const modules = Object.keys(masterclass.modules)
        const currentModuleIndex = modules.indexOf(moduleId)
        if (currentModuleIndex >= modules.length) {
            return
        }

        return modules[currentModuleIndex + 1]
    }

    /** The controls to control the video, ex Play, Pause, Rewind, Full Screen, etc */
    const VideoControls = () => {
        return (
            <div>
                <Button className="mx-2" style={{
                    backgroundColor: Constants.Purple,
                    border: "none",
                    width: "50px"
                }} onClick={rewind}>
                    <FontAwesomeIcon icon={faFastBackward} />
                </Button>

                {/* PLAY OR PAUSE BUTTON */}
                <Button className="mx-2" style={{
                    backgroundColor: Constants.Purple,
                    border: "none",
                    width: "50px"
                }} onClick={play}>
                    <FontAwesomeIcon icon={playing ? faPause : faPlay} />
                </Button>

                <Button className="mx-2" style={{
                    backgroundColor: Constants.Purple,
                    border: "none",
                    width: "50px"
                }} onClick={fastForward}>
                    <FontAwesomeIcon icon={faFastForward} />
                </Button>

                <Button className="mx-2" style={{
                    backgroundColor: Constants.Purple,
                    border: "none",
                    width: "50px"
                }} onClick={fullScreen ? handle.exit : handle.enter}>
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </Button>
            </div>
        )
    }

    /** Whether or not the show the custom video player controls */
    const showVideoPlayerControls = () => {
        return (masterclass.tag != undefined && masterclass.tag.sessionReplay == true) || FireService.moduleIsFinished(props.userDoc, moduleId) || FireService.userIsAdmin(props.userDoc.id)
    }

    /** Video has started */
    const onStart = () => {
        setLoading(false)
    }

    /** Video is ready to be played */
    const onReady = () => {
        if (ref.current == null) { return }
        if (FireService.moduleIsFinished(props.userDoc, moduleId) || FireService.userIsAdmin(props.userDoc.id)) {
            return
        }

        const progress = FireService.progressForModule(props.userDoc, moduleId)
        ref.current.seekTo(progress)

        updateFarthestPosition(progress)

        setFarthestPosition(progress)

        if (!module || !module.length) { return }

        const formattedTimeLeft = getTime(((module.length * 60) - progress) / 60)
        setTimeLeft(`${formattedTimeLeft} left in video`)
    }

    /** Check to see if the user is currently viewing this page on an iOS device */
    const iOS = () => {
        var iosQuirkPresent = function () {
            var audio = new Audio();

            audio.volume = 0.5;
            return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
        };

        var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        var isAppleDevice = navigator.userAgent.includes('Macintosh');
        var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

        return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    }

    return (
        <div>
            {/* {
                module &&
                <Modal size="lg" show={showRating} animation={true} centered>
                    <ModalBody>
                        <Finished forClass={isClassFinished()} setShowing={setShowRating} module={module} userDoc={props.userDoc} nextModuleId={getNextModuleId()} masterclass={masterclass} />
                    </ModalBody>
                </Modal>
            } */}
            {
                module &&
                <Container className="mt-5">
                    <Row>
                        <Col xs="12">
                            <h1>{module.name}</h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Image src={masterclass?.trainer?.imageUrl} height="100" width="100" roundedCircle />
                        </Col>
                        <Col xs="12">
                            <Container>
                                <Row >
                                    <Col xs="12" className="mt-4">
                                        <strong>{masterclass?.trainer?.name}</strong>
                                    </Col>
                                    <Col xs="12">
                                        Length of lesson: {getTime(module.length ?? 0)}
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    <Row>
                        {
                            module?.supplies &&
                            <div className="alert alert-danger mt-4">
                                <Col xs="12">
                                    <strong>IMPORTANT:</strong> Please make sure that you have all the necessary supplies.  The necessary supplies are listed below.
                                </Col>
                                <Col xs="12" className="mt-4">
                                    <strong>Necessary supplies for this module:</strong>
                                </Col>
                                <Col xs="12">
                                    {module?.supplies}
                                </Col>
                            </div>
                        }
                    </Row>

                    <Row>
                        <Col xs="12" className="mt-4">
                            <h3>Module Description</h3>
                            <hr />
                        </Col>
                        <Col >
                            {
                                <div className="mt-3">
                                    <span dangerouslySetInnerHTML={{ __html: module?.description || Utils.getDefaultDescriptionText(module) }} />
                                </div>
                            }
                        </Col>
                    </Row>
                    {
                        (!videoUrl || videoUrl?.indexOf('firebase')) != -1 &&
                        <div className="alert alert-primary mt-5">The video for this module is not ready yet.  Please check back later.</div>
                    }
                    {
                        FireService.classIsPurchased(props.userDoc, classId) && masterclass &&
                        <div>
                            {
                                // THE PLAYER 
                                // showVideo() &&

                                <Row className="mt-5">
                                    <Col xs="12" className="d-flex justify-content-center" style={{ height: "500px" }}>
                                        <FullScreen onChange={fullscreenToggled} handle={handle} >
                                            <ReactPlayer
                                                style={{ border: "black" }}
                                                url={videoUrl}
                                                ref={ref}
                                                onPlay={onPlay}
                                                onReady={onReady}
                                                onPause={onPause}
                                                onStart={onStart}
                                                playsinline={true}
                                                controls={(module.showControls == true) || showVideoPlayerControls()}
                                                playing={playing}
                                                height={videoDimensions.height}
                                                width={videoDimensions.width}
                                                onProgress={onProgress}
                                                onEnded={onEnded}
                                                config={
                                                    {
                                                        file: { forceHLS: true }
                                                    }
                                                } />
                                            {
                                                fullScreen &&
                                                <div className="fixed-bottom d-flex justify-content-center mb-2">
                                                    <VideoControls />
                                                </div>
                                            }
                                        </FullScreen>
                                    </Col>
                                </Row>
                            }
                            {
                                // THE VIDEO CONTROLS
                                videoUrl && !fullScreen && videoLoaded && (!masterclass.tag.sessionReplay) && !FireService.userIsAdmin(props.userDoc.id) && !FireService.moduleIsFinished(props.userDoc, moduleId) &&
                                <Row className="mt-2">
                                    {
                                        module.showControls !== true &&
                                        <Col xs="12" style={{ height: "50px" }} className="d-flex justify-content-center">
                                            <VideoControls />
                                        </Col>
                                    }
                                    <Col xs="12">
                                        <div className="d-flex justify-content-center my-2 text-center">
                                            <h5><strong>{currentTime}</strong></h5>
                                        </div>
                                    </Col>
                                    <Col xs="12">
                                        <div className="d-flex justify-content-center alert alert-warning my-2 text-center">
                                            {timeLeft}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {
                                !videoLoaded && !FireService.userIsAdmin(props.userDoc.id) && !FireService.moduleIsFinished(props.userDoc, moduleId) &&
                                <h3 className="d-flex justify-content-center">Loading Video...Please Wait</h3>
                            }
                            <Row>
                                <Col xs="12">
                                    <h1 style={{ color: Constants.Purple }}>Discussion</h1>
                                    <hr />
                                </Col>
                            </Row>
                            <Discussion module={module} masterclass={masterclass} currentTime={currentTimeInSeconds} />
                        </div>
                    }

                    {
                        !FireService.classIsPurchased(props.userDoc, classId) &&
                        <div className="alert alert-danger">
                            You have not purchased this class.  You must purchase this class in order to view this video.
                        </div>
                    }


                </Container>
            }
        </div >
    )
}

export default TakeModule