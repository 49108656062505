import React, { useState, useEffect } from 'react'
import { Constants } from '../objects/constants'
import firebase from 'firebase'
import { setuid } from 'process'

const Username = (props: { id?: string }) => {

    const [username, setUsername] = useState("loading...")

    useEffect(() => {
        var db = firebase.firestore()
        db.collection(Constants.USERS_COLLECTION).doc(props.id).get().then(snapshot => {
            const user = snapshot.data()
            
            if (!user) { return }

            setUsername(user?.name ?? "loading...")
        })
    }, [])

    return (
        <span>{ username }</span>
    )

}

export default Username