import React, { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import firebase from 'firebase'
import Utils from '../utils/images'
import FireService from '../firebaseService'
import { useAuth0 } from '@auth0/auth0-react'
import { Constants } from '../objects/constants'

const PMUNavbar = () => {

    const [authenticated, setAuthenticated] = useState(false)

    const [userIsAdmin, setUserIsAdmin] = useState(false)

    const { logout } = useAuth0()

    const signOut = () => {
        FireService.logout(logout)
    }

    useEffect(() => {
        FireService.initializeFirebaseApp()
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setAuthenticated(true)
                setUserIsAdmin(FireService.userIsAdmin(user.uid))
            } else {
                setAuthenticated(false)
                setUserIsAdmin(false)
            }
        })
    }, [])

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand>
                <img
                    alt=""
                    src="/logo.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
            PMU Masterclasses
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {
                        authenticated &&
                        <Button variant="outline-info" href="/">Dashboard</Button>
                    }
                    {
                        authenticated &&
                        <Form inline>
                            <Button className="mx-2 rounded-pill" onClick={signOut} style={{ backgroundColor: Constants.Red, border: "none" }}>Logout</Button>
                            {
                                userIsAdmin &&
                                <div>
                                    <Button variant="outline-success" href="/createclass" >Create New Class</Button>
                                    <Button variant="outline-primary" href="/giveclass" className="ml-3" >Revoke Class</Button>
                                </div>
                            }                                                        
                        </Form>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default PMUNavbar