import React, { useState } from 'react'
import { Modal, ModalBody, Container, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Constants } from '../objects/constants'
import { Masterclass } from '../objects/masterclass'

const AlreadyPurchasedModal = (props: { masterclass?: Masterclass, classId: string, close: any, message: string }) => {
    
    const history = useHistory()

    const gotoClassPressed = () => {
        history.push(`/class/${props.classId}/${props.masterclass?.basicInfo?.name ?? "masterclass"}/true`)
        props.close()
    }

    return (
        <div>
            {
                // Notify the user that they've already purchasd this class and if they'd like to go to to it now they can
                <Modal size="sm" show={true} animation={true} centered>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col xs="12" className="mt-3">
                                    { props.message }
                                </Col>
                                <Col xs="12" className="mt-4">
                                    <Button style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={ gotoClassPressed }>Go To Class</Button>
                                    <Button className="ml-3" variant="red" onClick={() => props.close()}>Cancel</Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            }
        </div>
    )

}

export default AlreadyPurchasedModal