export class Constants {
    public static CLASS_COLLECTION="masterclasses"
    public static CERTIFICATES_COLLECTION="certificates"
    public static VALID_ATTENDEES="attendee-2021"
    public static QUESTIONS_COLLECTION="questions"
    public static RATINGS_COLLECTION="ratings"
    public static FREE_CLASSES_COLLECTION="freeclasses"
    public static USERS_COLLECTION="users"
    public static VERIFIED_CERTIFICATES="verifiedCertificates"
    public static BANNED_USERS="bannedUsers"
    public static Purple = "#8e2d8e"
    public static Red = "#F55050"
    public static Green = "#2cb86b"
    public static SoftGreen = "#1698af"
    public static AppGreen = "#0E9984"
    public static LightGray = "#f9f9f9"
    public static TextGray = "#666666"
    public static LightPurple = "#9b59b6"
    public static PicoVoid = "#192a56"    
    public static MODULE_STARTED = "started"
    public static MODULE_FINISHED = "finished"

    
    public static CLASS_ID = "classId"
    public static STRIPE_SESSION_ID = "stripeSessionId"
    public static COUPON_ID = "couponId"
    public static FREE_CLASS_ID = "processing_free_class"

    public static ATTRIBUTES = "attributes"
    public static CONFERENCE_ATTENDEE = "conference_attendee"
    public static REDIRECT_URL = "redirectUrl"

    public static STRIPE_CANCEL_URL = "https://learn.pmumasterclasses.com/" 
    public static STRIPE_SUCCESS_URL_DEBUG = "http://localhost:3000/paymentsuccessful/"
    public static STRIPE_SUCCESS_URL_PROD = "https://learn.pmumasterclasses.com/paymentsuccessful/"

    public static PresentationDescriptionDefault = "<p>As you watch, make sure you take notes! If you have questions, ask those questions in the Q&A area below the video.  The system will automatically let others know the time in the video that you are referring to in the question.  By asking questions, other students or the speaker can answer those questions and help you better understand the information</p>"

    public static DemoDescriptionDefault = "<p>Watch the master trainer perform this procedure on a model. As you watch, look for tricks the master trainer uses that are new to you.</p><p>By carefully watching each step of the trainer's technique, you will find ways to improve your own skills.</p><p>As always, while you watch the video, ask questions in the Q&amp;A section under the video. Fellow students, and even the master trainer, can answer your questions to help you understand anything you find confusing. -- Your questions will also help other students learn more as they watch this video.</p>"

    public static COPYRIGHT_TEXT = "Copyright @2020 - iShapeBrows, LLC"

    public static Errors = {
        ALREADY_PURCHASED: "The masterclass has already been purchased",
        NO_MASTERCLASS_ID: "The id of the masterclass was null"
    }

    public static MasterclassCollectionKeys = {
        IN_MARKETPLACE: "inMarketplace"
    }

    public static MasterClassStates = {
        LIVE: "live",
        COMING_SOON: "coming_soon"
    }
}