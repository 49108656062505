import { loadStripe } from '@stripe/stripe-js'
import firebase from 'firebase'
import FireService from '../firebaseService'
import { Constants } from '../objects/constants'
import { UserDocument } from '../objects/userDocument'
import { v4 as uuidv4 } from 'uuid'
import Utils from '../utils/images'

class StripeUtils {
    
    static removeSession() {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) { return }

            firebase.firestore().collection(Constants.USERS_COLLECTION).doc(user.uid).update({
                stripeSession: firebase.firestore.FieldValue.delete()
            })
        })
    }

    /**
     * When a user purchases a class we take them to the Stripe Checkout page and then they're taken back here.  To make sure that when the purchase goes through they're actually given that class we store the classId that they're purchasing in the user's document stored in Firebase.  That's the purpose of this function
     * @param classId The id of the class to add the user's document.  This class id will be used to detect what class the user is currently purchasing
     */
    static addClassIdToUserForPurchasing(classId: string): Promise<string> {
        var db = firebase.firestore()
        var purchaseSessionId = "stripeSession.id"

        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => {

                if (user) {
                    FireService.getUser(user.uid).then(result => {
                        if (!result) { return }

                        var userDoc = result as UserDocument
                        if (userDoc.purchasedClasses[classId]) {
                            reject(Constants.Errors.ALREADY_PURCHASED)
                        }
                    })

                    var uploadObject: Record<string, string> = {}

                    uploadObject[purchaseSessionId] = classId
                    db.collection(Constants.USERS_COLLECTION).doc(user.uid).update(uploadObject).then(_ => {
                        resolve(purchaseSessionId)
                    }).catch(error => {
                        reject("There was an error adding the purchase session id")
                    })
                }
            })
        })
    }

    static process(purchaseObject: any, cancelUrl: string, successUrl: string, purchaseSessionId: string): Promise<boolean> {

        const stripePromise = loadStripe(Utils.isDebug() ? 'pk_test_g3Kjy1TfbEYVXGNO4JNgFi7X00yCxRSgmV' : 'pk_live_8nloNEBQNGfPpXzOkaTHERQV00HFI7oOfp')

        console.log("Clicked on a payment button")
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                purchaseObject,
                "cancelUrl": cancelUrl,
                "successUrl": `${successUrl}${purchaseSessionId}`
            })
        };

        return new Promise((resolve, reject) => {
            fetch(`${Utils.baseUrl()}/checkout`, requestOptions)
                .then(response => response.json())
                .then(response => response.sessionId)
                .then(async (sessionId) => {
                    const stripe = await stripePromise
                    if (!stripe) { return }
                    console.log(sessionId)
                    Utils.removeStripeDataFromLocalStorage()
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: sessionId
                    })

                    if (error) {
                        reject(error.message)
                    } else {
                        resolve(true)
                    }
                }).catch(error => {
                    console.log(error)
                    reject("Sorry, there was an error when taking you to the purchasing page.  Please contact us at info@pmumasterclasses.com so we can help you purchase the class.")
                })
        })

    }

}

export default StripeUtils