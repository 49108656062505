import React from 'react'
import { Constants } from "../objects/constants"
import { Container, Row, Col, Button, Image } from 'react-bootstrap'

const HorizontalBanner = (props: {
    bannerImage: string,
    url: string
}) => {

    return (
        <Container>
            <Row className="my-5">
                <Col className="text-center">
                    <div className="mb-2" style={{fontSize: "85%", color: Constants.TextGray}}>Advertisement | <a href={props.url}>Click to go to page</a></div>
                    <a href={props.url}><Image src={props.bannerImage} style={{width: "100%", border: "1px solid #000000"}} /></a>
                </Col>
            </Row>
        </Container>
    )
}

export default HorizontalBanner