import React, { useEffect, useState } from 'react'
import { Coupon } from '../objects/coupons';
import Utils from '../utils/images';

const useCoupon = ( classId: string ) => {

    const [coupon, setCoupon] = useState<Coupon>()

    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${Utils.baseUrl()}/couponCode?prefix=DEF&classId=${classId}`, requestOptions)
            .then(response => response.json())
            .then(response => response)
            .then(coupon => {
                setCoupon(coupon)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    return { coupon }
}

export default useCoupon