import React, { useState, useEffect, Props } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SimpleInput from '../../form/input';
import SimpleTextArea from '../../form/textArea';
import SaveButton from '../../saveButton';
import { MasterclassBasicInfo } from '../../../objects/masterclass';
import firebase from 'firebase';
import { Constants } from '../../../objects/constants';

const ClassType = (props: { classInformation?: MasterclassBasicInfo, classId?: string }) => {

    const [className, setClassName] = useState(props.classInformation?.name ?? "")

    const [classDescription, setClassDescription] = useState(props.classInformation?.description ?? "")

    const [classInformation, setClassInformation] = useState<MasterclassBasicInfo | undefined>(props.classInformation)

    const [price, setPrice] = useState(props.classInformation?.price ?? 0)

    const [loading, setLoading] = useState(false)

    const inputHeader = {
        color: "#575757"
    }

    const saveClassInformation = async () => {
        if (!classInformation || !props.classId) {
            return
        }

        const db = firebase.firestore()
        setLoading(true)
        await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({ basicInfo: classInformation })
        setLoading(false)

    }

    useEffect(() => {
        setClassInformation({
            name: className,
            description: classDescription,
            price: price
        })
    }, [className, classDescription, price])


    return (
        <Container>

            <SimpleInput
                value={props.classInformation?.name ?? ""}
                placeholder="What is the name of this class?"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat"
                headerName="Class Name"
                header={false}
                onChange={setClassName} />

            <SimpleInput
                value={props.classInformation?.price.toString() ?? ""}
                placeholder="How much will this class cost in USD?"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat"
                headerName="Class Price"
                header={false}
                onChange={setPrice} />

            <SimpleTextArea
                value={props.classInformation?.description ?? ""}
                placeholder="How would you describe what this class will offer?"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat"
                headerName="Class Description"
                header={false}
                onChange={setClassDescription} />

            <SaveButton loading={loading} action={saveClassInformation} title="Save Class Details" />
        </Container>
    )
}

export default ClassType