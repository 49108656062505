import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import SimpleInput from '../form/input';
import SimpleTextArea from '../form/textArea';
import AddImageInput from '../addImage/addImageInput';
import AddImage from '../form/addImage';
import { ClassModule } from '../../objects/masterclass';
import Utils from '../../utils/images';
import firebase from 'firebase';
import { Constants } from '../../objects/constants';
import SaveButton from '../saveButton';

const CreateModule = (props: { classModule: ClassModule, classId?: string }) => {

    /** Name of the module */
    const [moduleName, setModuleName] = useState(props.classModule.name ?? "")

    /** Supplies that will be needed when watching this module */
    const [moduleSupplies, setModuleSupplies] = useState(props.classModule.supplies ?? "")

    /** The description for this module, what the client will learn etc */
    const [moduleDescription, setModuleDescription] = useState(props.classModule.description ?? "")

    /** The url of the video for this module */
    const [videoUrl, setVideoUrl] = useState(props.classModule.videoUrl ?? "")

    /** The name of the video for this module  */
    const [videoName, setVideoName] = useState(props.classModule.videoName ?? "")

    /** The preview image for this module for clients to get an idea of what they'll accomplish by watching this module */
    const [imageUrl, setImageUrl] = useState(props.classModule.imageUrl ?? "")

    /** The module itself */
    const [classModule, setClassModule] = useState<ClassModule | undefined>(props.classModule)

    const imageRef = useRef<JSX.Element>(null)

    /** When the current module is in the loading state */
    const [loading, setLoading] = useState(false)

    /** What percentage of the video upload is currently at */
    const [percentUploaded, setPercentUploaded] = useState(0.0)

    /** The video to upload for this module */
    const [video, setVideo] = useState<File | Blob | undefined>()

    /** The actual image data for the preview image of this module */
    const [image, setImage] = useState<File | Blob | null>()

    /** The length of the video in minutes */
    const [length, setLength] = useState(props.classModule.length ?? 0)

    /** In what order this video should be in.  Just make sure that it's greater or lower than whatever the other video is */
    const [order, setOrder] = useState(props.classModule.order ?? 0)

    const [isPresentation, setIsPresentation] = useState(false)

    /** The unique id for this module */
    let moduleId = props.classModule.id

    useEffect(() => {
        setClassModule({
            id: props.classModule.id,
            name: moduleName,
            supplies: moduleSupplies,
            description: moduleDescription,
            videoUrl: videoUrl,
            videoName: videoName,
            imageUrl: imageUrl,
            length: length,
            order: order,
            isPresentation: isPresentation
        })
    }, [moduleName, moduleSupplies, moduleDescription, videoUrl, videoName, imageUrl, length, order, isPresentation])

    useEffect(() => {
        setClassModule(props.classModule)
        updateStateWithNewClassModule(props.classModule)
    }, [props.classModule])

    const updateStateWithNewClassModule = (classModule: ClassModule) => {
        setModuleName(classModule.name ?? "")
        setModuleSupplies(classModule.supplies ?? "")
        setModuleDescription(classModule.description ?? "")
        setVideoUrl(classModule.videoUrl ?? "")
        setVideoName(classModule.videoName ?? "")
        setImageUrl(classModule.imageUrl ?? "")
        setLength(classModule.length ?? 0)
        setOrder(classModule.order ?? 0)
        setIsPresentation(classModule.isPresentation ?? false)
    }


    // When the image has been selected, than we compress it and save it as a state object
    const onVideoAdded = async (evt: any) => {
        const files = evt.target.files
        const utils = new Utils()

        const videos = utils.fileListToArray(files)

        if (videos.length == 0) { return }
        const video = videos[0]
        setVideoName(video.name)
        setVideo(video)

        console.log("Video finished processing")
    }

    // When the image has been selected, than we compress it and save it as a state object
    const onImageAdded = async (images: File[]) => {

        const utils = new Utils()

        if (images.length == 0) { return }
        const compressedImage = await utils.compressImage(images[0])
        setImage(compressedImage)

        setImageUrl(URL.createObjectURL(compressedImage))

    }

    const deleteModule = async () => {
        if (!props.classId) { return }

        const db = firebase.firestore()

        var uploadObject: Record<string, any> = {}

        setLoading(true)

        // Since we can't use the construct of `` when updating a Firebase object, we need to define our key here
        const moduleSubDocumentKey = `modules.${moduleId}`
        uploadObject[moduleSubDocumentKey] = firebase.firestore.FieldValue.delete()

        await db.collection(Constants.CLASS_COLLECTION).doc(props.classId!).update(uploadObject)

        setLoading(false)
    }

    /** Save this module to the db */
    const saveModule = async () => {
        if (!props.classId || !classModule) { return }

        setLoading(true)
        const db = firebase.firestore()

        var imageRef = firebase.storage().ref().child(`masterclasses/${props.classId!}/photos/${moduleId}`)

        var uploadObject: Record<string, ClassModule> = {}

        // Since we can't use the construct of `` when updating a Firebase object, we need to define our key here
        const moduleSubDocumentKey = `modules.${moduleId}`

        uploadObject[moduleSubDocumentKey] = classModule

        let classModuleToUpload = classModule

        if (image) {
            var imageDownloadUrl = await (await imageRef.put(image!)).ref.getDownloadURL()
            classModuleToUpload.imageUrl = imageDownloadUrl
        }

        uploadObject[moduleSubDocumentKey] = classModuleToUpload

        classModuleToUpload.videoUrl = videoUrl

        // Update the class with the new module
        await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update(uploadObject)

        setLoading(false)
    }

    const uploadVideo = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (!props.classId || !classModule) { reject(new Error("Class Id or Class Module is undefined")) }
            var videoRef = firebase.storage().ref().child(`masterclasses/${props.classId!}/moduleVideos/${moduleId}-${videoName}`)
            if (video) {
                const uploadTask = videoRef.put(video!)
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
                    setPercentUploaded((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                }, async () => {
                    var videoDownloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
                    resolve(videoDownloadUrl)
                })
            } else {
                resolve("")
            }
        })
    }

    /**
     * When a user clicks the isPresented checkbox this, method updates the state for isPresentation
     * @param e 
     */
    const handleCheckboxSelectStateChanged = (e: any) => {
        setIsPresentation(e.target.checked)
    }

    return (
        <Container>
            <Row style={{ textAlign: "center" }}>
                <Col xs="12" style={{ fontWeight: "bold" }}>
                    Module Video
                </Col>
                <Col xs="12" className="mt-3">
                    <SimpleInput
                        placeholder="What is the url for this module's video?"
                        value={props.classModule.videoUrl ?? ""}
                        headerName="Video Url"
                        description="Enter the url for the video that you want to us for this module."
                        header={false}
                        onChange={setVideoUrl} />
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mt-3">
                    <AddImage imageUrl={props.classModule.imageUrl} onImagesAdded={onImageAdded} ref={imageRef} />
                </Col>

                {/* Below you'll see that we're setting the value of each input field to the value of the classModule object corresponding key-value pair,  ex: The lengths value is set to props.classModule?.length 
                
                The reason for this is that we want to make sure that if the class module changes, for example, when a new module is created, than all these values set to whatever the value is of the new classModule object.  This enables us to make sure that no bug creeps up where perhaps part of the information on this page is for the prvious module, and part for the new module */}
                <Col xs="12">
                    <SimpleInput
                        placeholder="What is the length of this module's video?"
                        value={`${props.classModule.length ?? 0}`}
                        headerName="Module video length"
                        description="Enter how long this video is in minutes"
                        header={false}
                        onChange={(setLength)} />

                    <SimpleInput
                        placeholder="In what order do you want this module to be in?"
                        value={`${props.classModule.order ?? 0}`}
                        headerName="Module order"
                        description="Enter in where in the order of the videos you want this video to play.  Do you want this video to play first (1), second (2), etc."
                        header={false}
                        onChange={setOrder} />

                    <SimpleInput
                        placeholder="What is the name of this module?"
                        value={props.classModule.name ?? ""}
                        headerName="Module name"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                        header={false}
                        onChange={setModuleName} />

                    <SimpleInput
                        placeholder="What supplies will be needed for this module?"
                        value={props.classModule.supplies ?? ""}
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                        headerName="Module supplies"
                        header={false}
                        onChange={setModuleSupplies} />

                    <SimpleTextArea
                        headerName="Module Description"
                        value={props.classModule.description ?? ""}
                        placeholder="How would you describe what you will be going over for this module?"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                        header={false}
                        onChange={setModuleDescription} />

                    {
                        loading &&
                        <h3>{`${percentUploaded}%`}</h3>
                    }

                    <label>
                        <input type='checkbox' checked={isPresentation} name="isPresentation" onChange={handleCheckboxSelectStateChanged} />
                        <span className="ml-2">Select if this is a presentation</span>
                    </label>

                    <SaveButton loading={loading} action={saveModule} title="Save Module" />
                    {/* <Button className="ml-2 rounded-pill" style={{ backgroundColor: 'red', border: 'none' }} onClick={deleteModule}>Delete Module</Button> */}
                </Col>
            </Row>
        </Container>
    )

}

export default CreateModule