import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal, ModalBody } from 'react-bootstrap'
import { Constants } from '../objects/constants'
import firebase from 'firebase'
import SignIn from '../pages/signin'
import StripeUtils from './stripe-utils'

/**
 * Responsible for allowing a user to make a purchase.  It simply takes the user to the Stripe checkout page.
 * 
 * If you're using StripePurchase on a page where the user does not need to be authenticated, but they need to be authenticated to make the purchase, then implementing the StripePurchase component can be somewhat tricky, here is what you want to do to implement in this kind of scenario.
 * 
 * Make sure that showLogin in the props object is set.  When this function is called within this component it will be passed the gotoCheckoutPage function.  Make sure you store this function so that it can be executed when the user has finished signing in.  So basically it would look something like this
 * 
 * Example:
 * <StripePurchase amount={100} purchaseObject={{ classId: masterclass.id, couponId: couponCode }} preRun={addPurchaseIdToUser} cancelUrl={window.location.href} successUrl={`${window.location.origin}/paymentsuccessful/`} showLogin={updateLoginState} title={`Purchase Class NOW for $${Number(masterclass.basicInfo?.price) - discount}`} />
 * 
 * const updateLoginState = (gotoCheckoutPage: any) => {
        setShowLogin(true)
        // Store the gotoCheckoutPage function
        setActivateStripePurchase(gotoCheckoutPage)        
    }

    // This will be called whenever the user has signed in successfully
    const takeUserToCheckoutPage = () => {
        if (!activateStripPurchase) {
            return
        }
        // Execute the gotoCheckoutPage function which was received from StripePurchase
        activateStripPurchase()
    }
 * @param props - the preRun function is where you want to run some code in order to generate an id that you can then retrieve later for whatever purpose you have in mind.  For example, we don't want to actually set a class as purchased for a user until the payment has gone through, so we create a purchase session which has a given id, we then pass that id to the success url and then once payment is completed we add that class as purchased to the user's document.
 */
const StripePurchase = (props: {
    showLogin?: any,
    purchaseObject: any,
    cancelUrl: string,
    couponCode?: string,
    title: string,
    successUrl: string,
    amount: number,
    // The function to be run before handling any payment processing
    preRun?: () => Promise<string>
}

) => {

    const [processing, setProcessing] = useState(false)

    const [showLogin, setShowLogin] = useState(false)

    const [error, setError] = useState("")

    const isDebug = () => {
        if (window.location.href.indexOf('localhost') != -1) {
            return true
        }

        return false
    }

    const handleErrors = (response: any) => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }

        return response.json()
    }
    const gotoCheckoutPage = (purchaseObject: any) => {
        setProcessing(true)
        setError("")
        firebase.auth().onAuthStateChanged(user => {

            if (!user) {
                // If the user is not logged in, then we need to show the login modal on whichever screen the user is trying to purchase from
                // We provie the gotoCheckoutPage function so that it can be executed on login
                props.showLogin()
                setProcessing(false)
                return
            }

            if (!props.preRun) {
                StripeUtils.process( purchaseObject, props.cancelUrl, props.successUrl, "").then(_ => {
                    console.log("The user was successfully sent to the checkout page")
                }).catch(error => {
                    setError(error)
                    setProcessing(false)
                })

                return
            }

            props.preRun().then(purchaseSessionId => {
                StripeUtils.process(purchaseObject, props.cancelUrl, props.successUrl, purchaseSessionId).catch(error => {
                    setError(error)
                    setProcessing(false)
                })
            })
        })
    }

    return (
        <div>
            {
                !processing &&
                <Button variant="primary" style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={() => gotoCheckoutPage(props.purchaseObject)}><strong>{props.title}</strong></Button>
            }
            {
                processing &&
                <div><strong>Please wait...</strong></div>
            }
            {
                error &&
                <div className="mt-2" style={{ color: "red" }}>{error}</div>
            }
        </div>
    )
}

export default StripePurchase