import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Constants } from '../objects/constants'
import { Coupon } from '../objects/coupons'
import Utils from '../utils/images'

const ApplyCouponBanner = (props: {
    classId: string
}) => {

    /**
     * The most current and highest saving coupon possible for the current class
     */
    const [coupon, setCoupon] = useState<Coupon>()

    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${Utils.baseUrl()}/couponCode?prefix=DEF&classId=${props.classId}`, requestOptions)
            .then(response => response.json())
            .then(response => response)
            .then(coupon => {
                setCoupon(coupon)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div>
            {
                coupon &&
                <Container className="my-5">
                    <Row className="text-center">
                        <Col xs="12" style={{ borderWidth: "1px", borderColor: Constants.SoftGreen, "borderStyle": "dashed" }}>
                            <div className="my-5 mx-5" style={{ color: Constants.SoftGreen }} >
                                <h3>Don't have a coupon code? Use code <strong>{coupon.concatedCode} </strong> and SAVE <strong>${coupon?.savings}</strong> when you register before <strong>{new Date(coupon.expires._seconds * 1000).toLocaleDateString() }</strong> </h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
        </div>

    )

}

export default ApplyCouponBanner