import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import CreateClassAccordion from '../components/createClassAccordion'
import CreateModuleAccordion from '../components/createModuleAccordion'
import { Masterclass, ClassModule } from '../objects/masterclass'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import { Constants } from '../objects/constants'
import { useParams, useHistory } from 'react-router-dom'
import SaveButton from '../components/saveButton'
import Utils from '../utils/images'
import FireService from '../firebaseService'

const CreateClass = () => {

    /** The Masterclass representing the class itself */
    const [masterclass, setMasterclass] = useState<Masterclass | undefined>()

    /** The modules for the class.  The modules contain the actual content of the class, the videos are stored within modules */
    const [modules, setModules] = useState<ClassModule[]>([])

    /** 
     * The id for the current masterclass that's being created.  If this is set within the params, than you will be able to update 
     * an already started created class
     */
    let { classId } = useParams()

    const [userId, setUserId] = useState("")

    const history = useHistory()

    useEffect(() => {
        FireService.initializeFirebaseApp()
        
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUserId(user.uid)                

                if (!classId) {
                    classId = uuidv4().substring(0, 8)
                    createClass(user.uid)
                } else {
                    watchClassDocument()
                }
            }            
        })

    }, [])

    /**
     * Create a new class
     */
    const createClass = (userId: string) => {

        const db = firebase.firestore()

        db.collection(Constants.CLASS_COLLECTION).doc(classId).set({
            id: classId,
            userId: userId
        }).then(ref => {
            console.log("Document written with ID: ", classId)
            watchClassDocument()
        }).catch(error => {
            console.log("Error adding document: ", error)
        })
    }

    /**
     * Watch the class document so that any changes that are made will automatically display throughout the application
     */
    const watchClassDocument = () => {
        const db = firebase.firestore()

        db.collection(Constants.CLASS_COLLECTION).doc(classId).onSnapshot(doc => {
            let masterclass: Masterclass | undefined = doc.data()
            setMasterclass(masterclass)
            
            setModules(Utils.sortClassModules(masterclass) ?? [])
        })
    }

    /**
     * Create a new module and add it to the database
     */
    const addModule = () => {
        const db = firebase.firestore()
        const moduleId = uuidv4().substring(0, 8)

        var newModule: Record<string, any> = {}        
        const moduleKey = `modules.${moduleId}`
        newModule[moduleKey] = { id: moduleId }

        db.collection(Constants.CLASS_COLLECTION).doc(classId).update(newModule)
    }

    const submitForReview = () => {
        history.push(`/class/review/${masterclass?.id}`)
    }

    return (
        <div>
            {
                masterclass &&
                <Container>
                    <Row>
                        <Col>
                            <CreateClassAccordion masterclass={masterclass} />                            
                            {
                                modules.length > 0 &&
                                modules.map(module => {
                                    return (
                                        <CreateModuleAccordion classModule={module} classId={masterclass?.id} />
                                    )
                                })
                            }

                            <Button onClick={addModule}  style={{
                                backgroundColor: "gray",
                                border: "none",
                                width: "100%"
                            }} className="rounded-pill mb-3">+ Click to add another module</Button>
                        </Col>
                    </Row>
                    <Row>
                        <SaveButton loading={false} title="Preview Class" action={submitForReview}></SaveButton>
                    </Row>
                </Container>
            }
        </div>


    )

}

export default CreateClass