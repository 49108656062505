import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const SimpleTextArea = (props: {
    headerName: string,
    value: string,
    placeholder: string,
    description?: string,
    header: boolean,
    onChange: any,
    height?:number,
    disabled?:boolean
}) => {

    const [value, setStateValue] = useState(props.value)

    const setValue = (value: any) => {
        setStateValue(value.target.value)
        props.onChange(value.target.value)
    }

    useEffect(() => {        
        setStateValue(props.value ?? "")
    }, [props.value])

    const simpleInputStyle = (height: number = 100): {} => {
        if (props.header === true) {
            return {
                fontSize: '18px',
                fontWeight: 'bold',
                border: 'none',
                height: height ?? '100px'
            }
        }
        return {
            fontSize: '16px',
            border: 'none',
            fontWeight: '600',
            height: height ?? '100px'
        }
    }

    const lineStyle = {
        marginLeft: '15px'
    }

    return (
        <Row>
            <Col xs="12" className="my-3" style={{ fontWeight: "bold" }}>
                {props.headerName}
            </Col>

            {
                props.description &&
                <Col xs="12" className="mb-3" style={{ color: "#8c8c8c" }}>
                    {props.description}
                </Col>
            }

            <Col style={{
                paddingLeft: "0px",
                paddingRight: "0px"
            }}>
                <Form.Control
                    as="textarea"
                    size="lg"
                    type="text"
                    placeholder={props.placeholder}
                    style={simpleInputStyle(props.height)}
                    value={value}
                    onChange={setValue}
                    disabled={props.disabled} />
                <hr className="mt-0" style={lineStyle}></hr>
            </Col>
        </Row>

    )

}

export default SimpleTextArea