import React, { useRef, useState } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import Utils from '../../../utils/images';
import AddImageInput from '../../addImage/addImageInput';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid'
import { Constants } from '../../../objects/constants';
import SaveButton from '../../saveButton';

const AddClassPhotos = (props: { classId?: string, imageUrls?: string[] }) => {

    const imageRef = useRef<JSX.Element>(null)

    const [classImages, setClassImages] = useState<(Blob | null)[]>()

    /** URL of the image selected by the user  */
    const [imageFileUrls, setImageFileUrls] = useState<string[]>(props.imageUrls ?? [])

    const [loading, setLoading] = useState(false)

    // When the image is has been selected, than we compress it and save it as a state object
    const onImagesAdded = async (evt: any) => {
        const files = evt.target.files
        const utils = new Utils()

        const images = utils.fileListToArray(files)

        if (images.length == 0) {
            return
        }

        const compressedImagesPromise = Promise.all(images.map(async image => await utils.compressImage(image)))

        compressedImagesPromise.then(compressedImages => {

            const compressedImagesUrls = compressedImages.map(compressedImage => URL.createObjectURL(compressedImage))

            setImageFileUrls(imageFileUrls?.concat(compressedImagesUrls) ?? compressedImagesUrls)
            setClassImages(classImages?.concat(compressedImages) ?? compressedImages)
        })
    }

    const removeImage = (removeUrl: string) => {
        const indexOfUrl = imageFileUrls?.indexOf(removeUrl)

        if (indexOfUrl == -1) {
            return
        }

        setClassImages(classImages?.splice(indexOfUrl, 1))
        setImageFileUrls(imageFileUrls?.filter(url => url != removeUrl))
    }

    const savePhotos = () => {
        if (!classImages || !props.classId) {
            return
        }

        const db = firebase.firestore()

        setLoading(true)

        const imagesRefUploadPromise = Promise.all(classImages.map(classImage => getImageReferenceForImageWithName(uuidv4()).put(classImage!)))

        imagesRefUploadPromise.then(result => {
            const downloadUrlPromises = Promise.all(result.map(async snapshot => await snapshot.ref.getDownloadURL()))
            downloadUrlPromises.then(urls => {
                db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({
                    photoUrls: urls
                }).then(result => {
                    setLoading(false)
                    console.log("Photos uploaded for class")
                }).catch(error => {
                    setLoading(false)
                    console.log("Error uploading the photo ", error)
                })
            })


        })

    }

    const getImageReferenceForImageWithName = (name: string): firebase.storage.Reference => {

        return firebase.storage().ref().child(`masterclasses/${props.classId!}/photos/${name}`)

    }

    return (
        <Container>
            <Row>
                <Col xs="12">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                </Col>
                <Col xs="12" className="my-3" style={{ textAlign: "center", borderRight: "1px dashed #333" }}>
                    {<AddImageInput onChange={onImagesAdded} />}
                </Col>
                <Col className="justify-content-center align-self-center" xs="12" style={{ textAlign: "center" }} >
                    {
                        !imageFileUrls || imageFileUrls?.length == 0 &&
                        <div>Images that you add will appear here</div>
                    }
                    {
                        imageFileUrls && imageFileUrls?.length > 0 &&
                        <div>
                            <Container>
                                <Row>
                                    {
                                        imageFileUrls.map(imageFileUrl => {
                                            return (
                                                <Col xs="12" md="3" className="mx-3" >
                                                    <Image src={imageFileUrl} thumbnail />
                                                    <Button variant="dark" className="rounded-pill mt-3" onClick={() => removeImage(imageFileUrl)}>Remove</Button>
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                            </Container>
                        </div>
                    }

                </Col>
                <Col>
                    <SaveButton loading={loading} action={savePhotos} title="Save Photos for Class" />
                </Col>
            </Row>
        </Container >
    )

}

export default AddClassPhotos