import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Card, Button, CardColumns, Modal, ModalBody, InputGroup, FormControl } from 'react-bootstrap'
import { Masterclass } from '../objects/masterclass'
import firebase, { User } from 'firebase'
import { Constants } from '../objects/constants'
import { useHistory } from 'react-router-dom'
import FireService from '../firebaseService'
import { UserDocument } from '../objects/userDocument'
import ClassElement from '../components/classelement'
import SimpleInput from '../components/form/input';
import { useAuth0 } from '@auth0/auth0-react'
import HorizontalBanner from '../components/horizontalBanner'
import Marketplace from '../components/marketplace'

const Dashboard = (props: { userDoc: UserDocument }) => {

    const history = useHistory()

    // [a,b] = someFunction(...) see: "Destructuring Assignment" https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

    /* per: https://reactjs.org/docs/hooks-overview.html, 
       The first node in the pair is a BOUND stateVariable and
       the second node is a method that can be used to set the 
       value of that state variable.

       And the value passed in the params in useState(value)
       is the INITIAL value of the stateVariable.
    */
    const [showSessionReplays, setShowSessionReplays] = useState<boolean>(false)

    /** All classes created by the current user */
    const [createdClasses, setCreatedClasses] = useState<Masterclass[]>()

    /** All classes purchased by the current user */
    const [purchasedClasses, setPurchasedClasses] = useState<Masterclass[]>([])

    /** All classes that are live and haven't been purchased by this user */
    const [liveClasses, setLiveClasses] = useState<Masterclass[]>()

    /** The name of the user */
    const [name, setName] = useState<string>("")

    /** Whether the user is authenticated through Auth0 or not */
    const { user, isAuthenticated, logout } = useAuth0()

    /** The card that displays all the purchased classes by the current user */
    const purchasedClassesCard = useRef<HTMLDivElement>(null)

    const [certificatesUrl, setCertificatesUrl] = useState("")

    const showCert = true

    /** The card that displays all the purchased classes by the current user */
    const sessionReplaysCard = useRef<HTMLDivElement>(null)

    const [sessionReplays, setSessionReplays] = useState<Masterclass[]>()

    const [search, setSearch] = useState("")

    const [allPurchasedClasses, setAllPurchasedClasses] = useState<Masterclass[]>([])    

    /** The current banners to show the user.  The banners are our way of giving away free classes */
    const [banners, setBanners] = useState<[{
        tag: string,
        showBanner: boolean,
        buttonText: string,
        bannerTitle: string,
        bannerText: string
    }]>()

    useEffect(() => {
        FireService.initializeFirebaseApp()        

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                console.log("Use effect on first render ran, user is defined...");
                getClasses(user)
                getPurchasedClasses(user.uid, false)                
                getLiveClasses()
            }
            else
            {
                console.log("Use effect on first render ran, but user was undefined.")
            }
        })
    }, []) //we want to run this method only once. After the initial render. That is why we pass in an empty array. see: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once

    useEffect(() => {
        if (props.userDoc.id) {
            setName(props.userDoc.name)
        }
    }, [props.userDoc])    

    const getClasses = async (user: User) => {
        var db = firebase.firestore()

        if (FireService.userIsAdmin(user.uid)) {
            let classes = (await db.collection(Constants.CLASS_COLLECTION).get()).docs.map(doc => doc.data())
            setCreatedClasses(classes.filter(masterclass => masterclass.status != 'down'))
        } else {
            let classes = (await db.collection(Constants.CLASS_COLLECTION).where("userId", "==", user.uid).get()).docs.map(doc => doc.data())
            setCreatedClasses(classes.filter(masterclass => masterclass.status != 'down'))
        }
    }

    const getLiveClasses = async () => {
        var db = firebase.firestore()

        db.collection(Constants.CLASS_COLLECTION).where('status', '==', 'live').get().then(snapshot => {
            let classes = snapshot.docs.map(doc => doc.data())

            setLiveClasses(classes)
        })
    }

    const getPurchasedClasses = async (userId: string, scrollToClasses: boolean) => {
        console.log("starting getPurchasedClasses");
        var db = firebase.firestore()
        console.log("grabbed reference to db");
        const userDoc = (await db.collection(Constants.USERS_COLLECTION).doc(userId).get()).data()        
        console.log("loaded user doc");

        if (!userDoc || !userDoc.purchasedClasses) { 
            console.log("skipping load classes, test returned...")
            console.log(!userDoc || !userDoc.purchasedClasses)
            return 
        }

        var promises = Promise.all(Object.keys(userDoc.purchasedClasses).map(classId => db.collection(Constants.CLASS_COLLECTION).doc(classId).get()))
                
        promises.then(snapshots => {
            const masterclasses = snapshots.map(snapshot => snapshot.data() as Masterclass)

            if (masterclasses as Masterclass[]) {
                setPurchasedClasses(purchasedClasses.concat(masterclasses.filter(c => !c.tag || c.tag.marketplace == true)))
                setAllPurchasedClasses(allPurchasedClasses.concat(masterclasses.filter(c => !c.tag)))
                console.log("Filtering masterclasses into session replays by calling setSessionReplays");
                setSessionReplays(masterclasses.filter(c => c.tag?.sessionReplay == true).sort((a, b) => ((a.order ?? -1) > (b.order ?? -1)) ? 1 : -1))
                console.log("Session replays state variable has been set via setSessionReplays");
                setShowSessionReplays(true);
            }            

            if (scrollToClasses) {
                sessionReplaysCard.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                })
            }

        })
    }

    /**
     * Log the user out using Firebase and Auth0 (Auth0 if they were logged in with that account)
     */
    const signOut = () => {
        FireService.logout(logout)
    }

    /**
     * Filters all the live classes and returns only those classes which are available to purchase
     */
    const availableClasses = () => {
        if (!liveClasses) { return [] }
        return liveClasses.filter(masterclass => (purchasedClasses?.indexOf(masterclass) != -1 && createdClasses?.indexOf(masterclass) != -1))
    }

    /**
     * Returns whether or not a class has been purchased by the currently logged in user
     * @param masterclass The class to check if it's purchased
     */
    const isClassPurchased = (masterclass: Masterclass) => {
        if (!masterclass.id) { return }
        if (masterclass.userId == props.userDoc.id) { return true }

        if (!props.userDoc.purchasedClasses) { return false }

        return Object.keys(props.userDoc.purchasedClasses).indexOf(masterclass?.id) != -1
    }

    /**
     * Saves the name of the currently logged in user to the server.  Does not save it to the actual account, but instead to this user's Firestore document 
     * @param name The name of the user
     */
    const saveName = (name: string) => {
        if (name.length > 1) {
            FireService.updateUser({ name: name }).catch(error => {
                console.log(error)
            })

            setName(name)
        }
    }

    /**
     * Returns whether a banner should be shown to the current user or not
     * @param banner The banner that should or should not be shown
     */
    const shouldShowBanner = (banner: any) => {
        if (FireService.userIsAdmin(props.userDoc.id)) {
            return true
        }

        return banner.showBanner
    }

    const PromptName = () => {

        const [nameEntered, setNameEntered] = useState("")

        return (
            <Container>
                <Row>
                    <Col xs="12">
                        <h5>We need your name for any certificates you get while using PMU Masterclasses</h5>
                    </Col>
                    <Col xs="12">
                        <SimpleInput
                            value={nameEntered ?? ""}
                            headerName="Please enter your first and your last name."
                            placeholder="Enter your first and last name"
                            header={false}
                            onChange={setNameEntered} />
                    </Col>
                    <Col className="mt-4" xs="12">
                        <Button style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={() => saveName(nameEntered)} >Save</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    const getFreeClasses = (tag: string) => {        
        FireService.getFreeClassesWithTag(tag).then(result => {
            if (result) {
                getPurchasedClasses(props.userDoc.id, true)
            }
        })
    }

    /**
     * As the user enters text into the search field we display only the classes that match the search criteria
     * @param event 
     */
    const filterClasses = (event: any) => {
        if (!purchasedClasses || !allPurchasedClasses) { return }

        let search: String = event.target.value

        if (search.trim().length == 0) {
            setPurchasedClasses(allPurchasedClasses)
            return
        }

        setPurchasedClasses(allPurchasedClasses.filter(c => {
            return c.basicInfo?.name.toLowerCase().indexOf(search.toLowerCase()) != -1
                || c.basicInfo?.description.toLowerCase().indexOf(search.toLowerCase()) != -1
                || c.trainer?.name.toLowerCase().indexOf(search.toLowerCase()) != -1
        }))
    }

    return (
        <div>
            {
                < Modal size="lg" show={name == undefined} animation={true} centered >
                    <ModalBody>
                        <PromptName />
                    </ModalBody>
                </Modal >
            }
            <Container className="mt-5">
                <Row>
                    <Col>
                        <div className="jumbotron" style={{ backgroundColor: Constants.Purple, color: "white" }}>
                            <h1 className="display-5" >Hello {name ?? 'Artist'}!</h1>
                            <p className="lead"></p>
                            <hr className="my-4" style={{ backgroundColor: "white" }} />
                            <p>This is your PMU Masterclasses dashboard. From here you can purchase classes and view classes that you've already purchased.</p>
                            <p className="lead">
                                <Button className="rounded-pill" onClick={signOut} style={{ backgroundColor: Constants.Red, border: "none" }}>Logout</Button>
                            </p>
                        </div>
                    </Col>
                </Row>

                <div>
                    {
                        showCert && certificatesUrl &&
                        <Row>
                            <Col>
                                <div className="jumbotron" style={{
                                    backgroundColor: "white",
                                    color: Constants.Purple,
                                    borderWidth: "2px",
                                    borderColor: Constants.Purple,
                                    borderStyle: "dotted"
                                }}>
                                    <h1 className="display-4" >Time To Get Your Certificates</h1>
                                    <p className="lead"></p>
                                    <hr className="my-4" style={{ backgroundColor: "white" }} />
                                    <p>Congratulations! You attended 3 days of amazing training at 'the PMU Conference' and now it's time for you to get your hard earned certificates.</p>
                                    <p>
                                        Click on the button below to go to the page where you can get your certificates sent to you through email.
                                    </p>

                                    <p className="lead">
                                        {
                                            <Button className="rounded-pill" onClick={() => history.push(certificatesUrl)} style={{ border: "none" }}>Click Here To Get Your Certificates Emailed To You</Button>
                                        }
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        banners?.map(banner => {
                            if (!shouldShowBanner(banner)) { return }
                            return (
                                <Row>
                                    <Col>
                                        <div className="jumbotron" style={{
                                            backgroundColor: "white",
                                            color: Constants.Purple,
                                            borderWidth: "2px",
                                            borderColor: Constants.Purple,
                                            borderStyle: "dotted"
                                        }}>
                                            <h1 className="display-4" >{banner.bannerTitle}</h1>
                                            <p className="lead"></p>
                                            <hr className="my-4" style={{ backgroundColor: "white" }} />
                                            <p><span dangerouslySetInnerHTML={{ __html: banner.bannerText }} /></p>

                                            <p className="lead">
                                                {
                                                    banner.buttonText &&
                                                    <p className="lead">
                                                        <Button className="rounded-pill" onClick={() => getFreeClasses(banner.tag)} style={{ border: "none" }}>{banner.buttonText}</Button>
                                                    </p>
                                                }
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </div>

                {
                    showSessionReplays &&
                    <div ref={sessionReplaysCard}>
                        <Card className="mb-5">
                            <Card.Header>
                                <div>
                                    <strong><h1>Your Complete Session Replays</h1></strong>
                                </div>
                                Below is where you will get access to the complete replay sessions from the PMU Conference you attended 9/14 to 9/16!
                                <hr />
                            </Card.Header>
                            <Card.Body>
                                <CardColumns>
                                    {
                                        sessionReplays?.map(masterclass => {
                                            return (
                                                <ClassElement masterclass={masterclass} isClassPurchased={isClassPurchased(masterclass)} />
                                            )
                                        })
                                    }
                                </CardColumns>
                                {
                                    (!sessionReplays || sessionReplays.length == 0) &&
                                    <div className="alert alert-primary">No replays available yet.  Your replays will show up here.</div>

                                }
                            </Card.Body>
                        </Card>
                    </div>
                }

                <Marketplace />

                <HorizontalBanner bannerImage="/banners/horizontal-banner-2.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />


                <div ref={purchasedClassesCard}>
                    <Card className="mb-5">
                        <Card.Header>
                            <div>
                                <strong><h1>Your Purchased Classes</h1></strong>
                            </div>
                            Below are all of the classes that you have purchased!
                            <hr />
                            <InputGroup size="sm" className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-sm">Search</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl onChange={filterClasses} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
                            </InputGroup>
                        </Card.Header>
                        <Card.Body>
                            <CardColumns>
                                {
                                    purchasedClasses?.map(masterclass => {
                                        return (
                                            <ClassElement masterclass={masterclass} isClassPurchased={isClassPurchased(masterclass)} />
                                        )
                                    })
                                }
                            </CardColumns>
                            {
                                (!purchasedClasses || purchasedClasses.length == 0) &&
                                <div className="alert alert-primary">No replays available yet.  Your replays will show up here.</div>

                            }
                        </Card.Body>
                    </Card>
                </div>

                {
                    FireService.userIsAdmin(props.userDoc.id) &&
                    <div>
                        <Card className="mt-5">
                            <Card.Header style={{ color: Constants.PicoVoid }}>
                                <div>
                                    <strong><h1>Classes You've Created</h1></strong>
                                </div>
                                Below are all of the classes that you've created using PMU Masterclass
                            </Card.Header>
                            <Card.Body>
                                <CardColumns>
                                    {
                                        createdClasses?.map(masterclass => {
                                            return (
                                                <ClassElement masterclass={masterclass} isClassPurchased={isClassPurchased(masterclass)} showEditClassButton={true} />
                                            )
                                        })
                                    }
                                </CardColumns>
                                {
                                    (!createdClasses || createdClasses.length == 0) &&
                                    <div className="alert alert-primary">YOU HAVE NOT CREATED ANY CLASSES YET</div>
                                }
                            </Card.Body>
                        </Card>

                        <Card className="mt-5">
                            <Card.Header>
                                <div>
                                    <strong><h1>Available Classes To Purchase</h1></strong>
                                </div>
                            Below are all of the classes that you can purchase.  Why not browse below and see what new skill you can learn?
                            </Card.Header>
                            <Card.Body>
                                <CardColumns>
                                    {
                                        availableClasses().map(masterclass => {
                                            return (
                                                <ClassElement masterclass={masterclass} />
                                            )
                                        })
                                    }
                                </CardColumns>
                                {
                                    (!liveClasses || availableClasses().length == 0) &&
                                    <div className="alert alert-primary">NO AVAILABLE CLASSES TO PURCHASE</div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                }

                <HorizontalBanner bannerImage="/banners/horizontal-banner-2.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

                <Row style={{ backgroundColor: Constants.LightGray }} className="my-3">
                    <Col xs="12" className="justify-content-center align-items-center my-3">
                        {Constants.COPYRIGHT_TEXT}
                    </Col>
                </Row>

            </Container >
        </div >
    )
}

export default Dashboard