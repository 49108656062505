import { constants } from 'buffer'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, CardColumns } from 'react-bootstrap'
import ClassElement from './classelement'
import { Constants } from '../objects/constants'
import { Masterclass } from '../objects/masterclass'

const Marketplace = () => {

    const [classes, setClasses] = useState<Masterclass[]>()

    useEffect(() => {
        const db = firebase.firestore()

        db.collection(Constants.CLASS_COLLECTION).where(Constants.MasterclassCollectionKeys.IN_MARKETPLACE, '==', true).get().then(snapshot => {
            const classes = snapshot.docs.map(doc => doc.data() as Masterclass)
            setClasses(classes)
        })
    }, [])

    return (
        <Container className="mt-5">
            <Card className="mb-5">
                <Card.Header>
                    <div>
                        <strong><h1>Classes Available to Purchase</h1></strong>
                    </div>
                    Below are all the classes currently available to purchase
                </Card.Header>
                <Card.Body>
                   <Container>
                        <Row>
                            <CardColumns>
                                {
                                    classes &&
                                    classes.map(masterclass => {
                                        return (
                                            <Col xs="12">
                                                <ClassElement masterclass={masterclass} revoking={false} purchasing={{
                                                    showCoupon: true
                                                }} />
                                            </Col>
                                        )
                                    })
                                }
                            </CardColumns>
                        </Row>
                    </Container> 
                </Card.Body>
            </Card>
        </Container>
        
    )
}

export default Marketplace