import imageCompression from "browser-image-compression";
import { Masterclass, ClassModule } from "../objects/masterclass";
import { Constants } from "../objects/constants";
import firebase from "firebase";

class Utils {

    static baseUrl() {
        return window.location.origin.indexOf('localhost') != -1 ? 'http://localhost:3001' : 'https://masterclasses.herokuapp.com'
    }


    /** Adds a masterclass to the list of purchased classes for the current user
     * 
     * @param mastercalssId - The id of the class that's been purchased or is being added to the list of purchased classes
     */
    static addClassToPurchasedClassesForCurrentUser (masterclassId: string) {        
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                var db = firebase.firestore()
                // Get the user document from the server
                let userRef = db.collection(Constants.USERS_COLLECTION).doc(user.uid)

                userRef.get().then(userObject => {
                    const userData = userObject.data()

                    if (!userData) { return }                                       
                    
                    var purchase: Record<string, boolean> = userObject?.data()?.purchasedClasses ?? {}

                    purchase[masterclassId] = true
                    userRef.update({
                        purchasedClasses: purchase,                            
                    })                    
                })
            }
        })
    }

    static removeStripeDataFromLocalStorage () {        
        localStorage.removeItem(Constants.COUPON_ID)
        localStorage.removeItem(Constants.CLASS_ID)
        localStorage.removeItem(Constants.STRIPE_SESSION_ID)
        localStorage.removeItem(Constants.FREE_CLASS_ID)
    }

    static isDebug() {
        if (window.location.href.indexOf('localhost') != -1) {
            return true
        }

        return false
    }

    /**
     * Based on of a class module is a presentation or a demo, the appropriate default description will be returned
     * @param classModule The module to get the default description for
     */
    static getDefaultDescriptionText (classModule: ClassModule) {
        if (classModule.isPresentation) {
            return Constants.PresentationDescriptionDefault
        }

        return Constants.DemoDescriptionDefault
    }

    static sortClassModules (masterclass:any) {
        const classModules:ClassModule[] = []
        
        if (!masterclass.modules) { return }

        Object.keys(masterclass.modules).map(moduleId => {
            classModules.push(masterclass.modules[moduleId])
        })

        classModules.sort( (a, b) =>  { 
            if (!a.order) {
                return -1
            }

            if (!b.order) {
                return 1
            }

            /**
             * Because of the way that TypeScript works during run-time TypeScript types are removed and since JavaScript does not have a 
             * concept of static types there's no way to ensure that the values of a and b are actually numbers, therefore we need to explicitly
             * state this at our check
             * 
             * For more information see: https://stackoverflow.com/questions/57323811/why-is-my-typescript-number-field-a-string
             */
            if (Number(a.order) < Number(b.order)) {
                return -1
            }

            return 1
        } )

        return classModules
    }

    static getCurrentTime (timeInSeconds: number) {
        const hours = Math.floor(timeInSeconds / 60 / 60)
        const minutes = Math.floor((timeInSeconds / 60) % 60)
        const seconds = Math.floor(((timeInSeconds % 60) % 60))
                
        return `${hours} : ${minutes < 10 ? "0" : ""}${minutes} : ${seconds < 10 ? "0" : ""}${seconds} `
        
    }

    // Compress the image
    async compressImage (image: File) {
        const options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: 2048,
            useWebWorker: false
        }

        try {
            const compressedFile = await imageCompression(image, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            return compressedFile
        } catch (error) {
            return null
        }
    }

    fileListToArray (list: any[]) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list[i]);
        }
        return array;
    }

}

export default Utils