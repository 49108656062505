import React, { useState, useEffect } from 'react'
import { Masterclass, ClassModule } from '../objects/masterclass'
import { Container, Row, Col, Image, Button, Modal, ModalBody, InputGroup, Form } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { Constants } from '../objects/constants'
import firebase from 'firebase'
import SaveButton from '../components/saveButton'
import Utils from '../utils/images'
import StripePurchase from '../components/StripePurchase'
import { v4 as uuidv4 } from 'uuid'
import FireService from '../firebaseService'
import { UserDocument } from '../objects/userDocument'
import SignIn from './signin'
import StripeUtils from '../components/stripe-utils'
import AlreadyPurchasedModal from '../components/alreadyPurchasedModal'
import ApplyCouponBanner from '../components/applyCoupon'
import Coupon from '../components/coupon'
import useCoupon from '../hooks/useCoupon'
import HorizontalBanner from '../components/horizontalBanner'
import { hotjar } from 'react-hotjar'

const ViewClass = (props: { review?: boolean, userDoc: UserDocument, isPurchasing?: boolean }) => {

    const [masterclass, setMasterclass] = useState<Masterclass>({})

    const [classModules, setClassModules] = useState<ClassModule[]>()

    const { classId } = useParams()

    const { classInProcess } = useParams()

    const purple = "#8e2d8e"

    const [loading, setLoading] = useState(false)

    const [pageLoading, setPageLoading] = useState(true)

    const [classIsPurchased, setClassIsPurchased] = useState(false)

    const [showLogin, setShowLogin] = useState(false)

    const history = useHistory()

    const [showPrompt, setShowPrompt] = useState<{ message: string, show: boolean }>({ message: "", show: false })

    const [couponCode, setCouponCode] = useState("")

    const [discount, setDiscount] = useState(0)

    const { coupon } = useCoupon(classId)

    const [classIsFree, setClassIsFree] = useState(false)

    const [userDoc, setUserDoc] = useState<UserDocument>()
    

    useEffect(() => {

        FireService.initializeFirebaseApp()
        hotjar.initialize(2046095, 0)

        var db = firebase.firestore()
        
        db.collection(Constants.CLASS_COLLECTION).doc(classId).get().then(snapshot => {
            const masterclass = snapshot.data()
            if (!masterclass) { return }

            setMasterclass(masterclass)

            setClassModules(Utils.sortClassModules(masterclass) ?? [])
            setPageLoading(false)

            firebase.auth().onAuthStateChanged(user => {
                if (!user) { return }
    
                db.collection('users').doc(user.uid).get().then(result => {
                    if (!result.exists) { return }
                    setUserDoc(result.data() as UserDocument)
                })
            })

        })
    }, [])

    useEffect(() => {
        if (!masterclass.id) { return }
        if (!userDoc) { return }

        if (userDoc.purchasedClasses) {
            const purchasedClasses = Object.keys(props.userDoc.purchasedClasses)
            if (purchasedClasses.indexOf(masterclass.id) != -1) {
                setClassIsPurchased(true)
            }
        }
    }, [userDoc])

    useEffect(() => {
        setClassIsFree(masterclass.basicInfo?.price == 0)
    }, [masterclass])

    const updateLoginState = (gotoCheckoutPage: any) => {
        setShowLogin(true)
    }

    /** Handle the retrieval of a free class */
    const getFreeClass = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                setShowLogin(true)
                return
            }

            if (!masterclass.id) {
                console.error("The id is not set for this masterclass")
                return
            }

            if (classIsPurchased) {
                setShowPrompt({
                    message: "You already have access to this class. Would you like to go there now?",
                    show: true
                })
            } else {
                setShowPrompt({
                    message: "Awesome! You now have access to this class. Would you like to go there now?",
                    show: true
                })
            }

            Utils.addClassToPurchasedClassesForCurrentUser(masterclass.id)
        })
    }

    const showPurchasingArea = () => {
        return (props.isPurchasing && !classIsFree) ?? false
    }

    const PurchaseArea = () => {
        return (
            <Col>
                <div style={{ width: "100%" }} className="mt-4" >
                    {
                        showPurchasingArea() &&
                        <div>
                            <Form>
                                <div>
                                    {
                                        coupon &&
                                        <div>
                                            <div className="alert alert-info"><p><a href={`https://airtable.com/shrwFyt3tTRPqZWfy?prefill_lookupId=${masterclass.id}`}><strong>Join our mailing list now</strong></a> and get a coupon code for <strong>an extra $100 OFF</strong>. 👍</p>
                                                <p><strong>You will also get offers to save up to 25% on PMU training!</strong> For example, our company is one of the organizers of "the PMU Conference".</p>
                                                <p>Besides our own events, we also work with Permanent Makeup trainers in the
                                            United States, Canada, Europe, South America and Asia.</p>
                                                <p>No matter where you live, we can help you <strong>save BIG</strong> on permanent
                                            makeup classes.</p>
                                                <p>As a member, <strong>we will email you discounts</strong> for masterclasses and free tips to help you run successfully run your permanent makeup business.</p>
                                                <p>So make sure you <a href={`https://airtable.com/shrwFyt3tTRPqZWfy?prefill_lookupId=${masterclass.id}`}>join our mailing list NOW!</a></p></div>
                                            <div className="alert alert-success">Don't have a coupon code for this class? Use code <strong>{coupon.concatedCode} </strong> and SAVE <strong>${coupon?.savings}</strong> when you register before <strong>{new Date(coupon.expires._seconds * 1000).toLocaleDateString()} </strong> (There are <strong>only {ticketsLeft()} classes left</strong> at today's price.) </div>
                                        </div>
                                    }
                                    {
                                        !couponCode &&
                                        <Coupon classId={classId} buttonColor={Constants.Purple} setDiscount={(discount: number) => { setDiscount(discount) }} setCouponCode={(code: string) => { setCouponCode(code) }} />
                                    }
                                    {
                                        couponCode &&
                                        <div className="my-3">
                                            <strong>Your coupon code {couponCode} has been applied! We've subtracted ${discount} from the price of this class for you! 😊 👍 To lock in this discounted price, click "Purchase Now" and complete your purchase before this coupon code expires. 😬</strong>
                                        </div>
                                    }
                                </div>
                                <Row>
                                    <Col xs="12">
                                        {
                                            classIsFree == false &&
                                            <StripePurchase amount={Number(masterclass.basicInfo?.price) - discount} purchaseObject={{ classId: masterclass.id, couponId: couponCode }} preRun={addPurchaseIdToUser} cancelUrl={window.location.href} successUrl={`${window.location.origin}/paymentsuccessful/`} showLogin={updateLoginState} title={`Purchase Class NOW for $${Number(masterclass.basicInfo?.price) - discount}`} />
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    }
                    {
                        props.isPurchasing && masterclass.basicInfo?.price == 0 &&
                        <div>
                            <Button variant="primary" style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={() => getFreeClass()}><strong>Get this class for FREE</strong></Button>
                        </div>
                    }
                </div>
            </Col>
        )
    }

    const addPurchaseIdToUser = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            StripeUtils.addClassIdToUserForPurchasing(classId).catch(error => {
                setShowLogin(false)

                switch (error) {
                    case Constants.Errors.ALREADY_PURCHASED:
                        setShowPrompt(
                            {
                                message: "You've already purchased this class.  Would you like to go there now?",
                                show: true
                            }
                        )
                        break;
                    case Constants.Errors.NO_MASTERCLASS_ID:
                        return
                    default:
                        break;
                }
            }).then(purchaseSessionId => {
                if (!purchaseSessionId) { return }
                resolve(purchaseSessionId)
            })
        })
    }

    const Photos = (props: { urls: string[] | undefined }) => {

        return (
            <div>
                {
                    props.urls?.map(url => {
                        return (
                            <Col xs="12" md="3">
                                <Image src={url} thumbnail />
                            </Col>
                        )
                    })
                }
            </div>
        )
    }

    const submit = () => {
        var db = firebase.firestore()
        setLoading(true)

        db.collection(Constants.CLASS_COLLECTION).doc(classId).update({
            status: "review"
        }).then(() => {
            setLoading(false)
            history.push('/submitted')
        }).catch(error => {
            console.log('Error submitting the masterclass for review', error)
            setLoading(false)
        })
    }

    const getModuleActionText = (module: ClassModule) => {

        if (props.isPurchasing && classIsFree) {
            return 'Get FREE Class'
        }

        if (props.isPurchasing) {
            return 'Purchase Class'
        }

        if (masterclass.tag && masterclass.tag.sessionReplay) {
            return 'Go To Video'
        }

        return module.isPresentation ? 'Go To Section' : 'Go To Demo'
    }

    const ticketsLeft = () => {
        return (masterclass?.tranche?.size ?? 7) - (masterclass?.tranche?.ticketsSold ?? 0)
    }

    return (
        <div className="mt-5">
            {
                masterclass.id && showPrompt.show &&
                // Notify the user that they've already purchasd this class and if they'd like to go to to it now they can                
                <AlreadyPurchasedModal message={showPrompt.message} masterclass={masterclass} classId={masterclass.id} close={() => setShowPrompt({ message: showPrompt.message, show: false })} />
            }
            {
                <Modal size="sm" show={showLogin} animation={true} centered>
                    <ModalBody>
                        <SignIn isPurchasing={true} couponId={couponCode} classId={classId} close={() => setShowLogin(false)} freeClass={classIsFree} />
                    </ModalBody>
                </Modal>
            }
            {
                pageLoading &&
                <div className="loading" style={{ height: "100%" }} >
                    <div className="justify-content-center align-items-center">
                        <Image className="loading" src="/30.gif" />
                    </div>
                </div>
            }
            {
                !pageLoading && showPurchasingArea() &&
                <ApplyCouponBanner classId={classId} />
            }
            {
                !pageLoading && showPurchasingArea() &&
                <div className="alert alert-success text-center">
                    There are <strong>only {ticketsLeft()} classes left</strong> at today's price.
                </div>
            }
            {
                !pageLoading &&
                <Container className="mt-4">
                    <Row>
                        {
                            props.review && masterclass.status != "review" &&
                            <div>
                                <Col xs="12">
                                    <div className="alert alert-primary">
                                        Please review carefully to make sure that all the information for the class and the modules are correct.
                                        Once you've finished reviewing click submit and we will begin reviewing your class.
                            </div>
                                </Col>
                                <Col xs="12">
                                    <SaveButton loading={loading} title="Submit For Review" action={submit} />
                                </Col>
                                <hr />
                            </div>
                        }
                        {
                            props.review && masterclass.status == "review" &&
                            <div>
                                <Col xs="12">
                                    <div className="alert alert-primary">
                                        This class is currently in review and can not be updated until approved or denied.  If you have any questions
                                        please contact us by emailing info@pmuconference.com
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <Button style={{ backgroundColor: "#2cb86b", border: "none" }} className="rounded-pill" href="/">Go to Dashboard</Button>
                                </Col>
                            </div>
                        }
                        <Col xs="12" style={{ color: purple }} className="mt-4">
                            <h1><strong>Masterclass</strong>: {masterclass.basicInfo?.name}</h1>
                        </Col>

                        {
                            (classInProcess == 'true' || masterclass.status == 'review') &&
                            <Col xs="12" className="mt-3">
                                <Button
                                    style={{ backgroundColor: Constants.Purple, border: "none" }}
                                    href="#modules" >
                                    Click Here to Jump To Masterclass Sections
                                </Button>
                                <hr />
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md="7" sm="12" xs="12" className="mt-4">
                            <Image src={masterclass.flyerUrl} width="100%" style={{ borderRadius: "20px" }} thumbnail />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" className="mt-5" style={{ color: purple }}>
                            <h2>Training You Get</h2>
                            <hr />
                        </Col>
                        <Col xs="12" className="mt-3">
                            <span dangerouslySetInnerHTML={{ __html: masterclass.basicInfo?.description ?? "" }} />
                        </Col>
                    </Row>
                    {
                        props.isPurchasing &&
                        <Row>
                            <PurchaseArea />
                        </Row>
                    }

                    <HorizontalBanner bannerImage="/banners/horizontal-banner-2.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

                    {
                        masterclass.certificateUrl &&
                        <div>
                            <Row>
                                <Col xs="12" className="mt-5 mb-3" style={{ color: purple }}>
                                    <h2>Certificate You Get</h2>
                                    <hr />
                                </Col>
                                <Col xs="12" md="5">
                                    <Image src={masterclass.certificateUrl} thumbnail />
                                </Col>
                                <Col xs="12" md="7">
                                    <Container>
                                        <Row>
                                            <Col xs="12">
                                                {
                                                    !masterclass.certificateDetails &&
                                                    <div>
                                                        For attending this class you will receive a FULLY digitally VERIFIABLE certificate of attendance.  This makes the certificate
                                                        that you recieve from attending this class even more valuable.  YOUR CLIENTS will be able to DIGITALLY verify that the CERTIFICATES
                                                        OF ATTENDANCE you get from attending this class ARE AUTHENTIC!
                                                    </div>
                                                }
                                                {masterclass.certificateDetails}
                                            </Col>
                                            {
                                                FireService.classIsFinished(props.userDoc, masterclass) && !props.isPurchasing &&
                                                <Col xs="12" className="mt-5">
                                                    <Button href={`/certificate/${masterclass.certificateTag}`} className="mb-2" style={{ backgroundColor: Constants.Purple, border: "none" }}>
                                                        Download Certificate
                                                </Button>
                                                </Col>
                                            }
                                        </Row>
                                    </Container>
                                </Col>

                            </Row>

                        </div>
                    }
                    {
                        showPurchasingArea() &&
                        <Row>
                            <PurchaseArea />
                        </Row>
                    }
                    <Row>

                        {
                            masterclass.photoUrls &&
                            <Col xs="12" style={{ color: purple }} className="mt-5">
                                <h2>Results you can get after this class</h2>
                                <hr />
                            </Col>
                        }
                        {
                            masterclass.photoUrls?.map(url => {
                                return (
                                    <Col xs="12" md="3">
                                        <Image src={url} thumbnail />
                                    </Col>
                                )

                            })
                        }
                    </Row>

                    <Row className="mt-5" style={{ color: purple }}>
                        <Col>
                            <h2>Masterclass Sections</h2>
                            <hr />
                        </Col>
                    </Row>

                    {/* Module Sections */}
                    <div id="modules">
                        {
                            classModules &&
                            classModules.map(module => {
                                return (
                                    <Row className="mt-5">
                                        <Col xs="12" md="5">
                                            <Image src={module.imageUrl} thumbnail />
                                        </Col>
                                        <Col xs="12" md="7">
                                            <Container className="mb-5">
                                                <Row>
                                                    <Col xs="12" style={{ color: purple }}>
                                                        <h2>{module.name}</h2>
                                                        <hr></hr>
                                                    </Col>
                                                    {
                                                        !props.isPurchasing &&
                                                        <Col xs="12" className="mt-3">
                                                            <Button
                                                                style={{ backgroundColor: Constants.Purple, border: "none" }}
                                                                onClick={() => history.push(`/module/${module.id}/${classId}/${module.name}`)} >
                                                                Click Here to {getModuleActionText(module)}
                                                            </Button>
                                                        </Col>
                                                    }

                                                    {
                                                        <div>
                                                            <Col xs="12" className="mt-3">
                                                                <strong>Description</strong>
                                                            </Col>
                                                            <Col xs="12" className="mt-3">
                                                                {
                                                                    <div>
                                                                        <p>
                                                                            <span dangerouslySetInnerHTML={{ __html: module.description || "" }} />
                                                                        </p>
                                                                        <p>
                                                                            <span dangerouslySetInnerHTML={{ __html: Utils.getDefaultDescriptionText(module) }} />
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </div>
                                                    }
                                                    {
                                                        module.supplies &&
                                                        <div>
                                                            <Col xs="12" className="mt-3">
                                                                <strong>Supplies needed:</strong>
                                                            </Col>
                                                            <Col xs="12" className="mt-3">
                                                                {module.supplies}
                                                            </Col>
                                                        </div>
                                                    }
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>

                    {
                        props.isPurchasing &&
                        <Row>
                            <PurchaseArea />
                        </Row>
                    }

                    <HorizontalBanner bannerImage="/banners/horizontal-banner-1.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

                    <Row>
                        <Col xs="12" className="mt-5" style={{ color: purple }}>
                            <h2>About This Trainer</h2>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-5" xs="12" md="5">
                            <Image src={masterclass.trainer?.imageUrl} thumbnail />
                        </Col>
                        <Col xs="12" md="7">
                            <Col xs="12" className="mt-3">
                                <h2 style={{ color: purple }}>{masterclass.trainer?.name}</h2>
                            </Col>
                            {
                                masterclass.trainer?.academyName &&
                                <Col xs="12" className="mt-4">
                                    <strong>Academy Name: </strong> {masterclass.trainer?.academyName}
                                </Col>
                            }
                            <Col xs="12" className="mt-3">
                                {
                                    masterclass.trainer?.bio &&

                                    <div>
                                        <div className="mt-3">
                                            <span dangerouslySetInnerHTML={{ __html: masterclass.trainer?.bio }} />
                                        </div>
                                    </div>
                                }
                            </Col>
                            {
                                showPurchasingArea() &&
                                <PurchaseArea />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mt-5" style={{ color: purple }}>
                            <h2>Frequently Asked Questions</h2>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 style={{ fontWeight: "bold" }}>When is this class?</h5>

                            <p>This is a PRE-RECORDED ONLINE class. That means you can take this class whenever you want! &#x1F60A;&#x1F64C;&#x1F64C;</p>

                            <h5 style={{ fontWeight: "bold" }}>After I buy access to the class, how long do I have to watch it?</h5>

                            <p>After you buy access to the class, <strong>you have access for 6 MONTHS</strong>. 😊👍</p>

                            <h5 style={{ fontWeight: "bold" }}>Do I get a certificate for completing the class?</h5>

                            <p>Yes! &#x1F60A; After you finish the class, <strong>you will get a certificate emailed to you.</strong> You can print the certificate out at that time.</p>

                            <h5 style={{ fontWeight: "bold" }}>What name will be on my certificate?</h5>

                            <p>When you get your certificate, our system will ask you for the name you want on your certificate.</p>

                            <p><strong>WARNING:</strong> <strong>Make sure you enter the CORRECT name for your certificate.</strong> If you put the wrong name, you will have to contact us to change it.</p>

                            <h5 style={{ fontWeight: "bold" }}>Can I share this class with other artists in my studio?</h5>

                            <p>No. Sharing training videos is a violation of our terms. It could result in you PERMANENTLY losing access to PMU Masterclasses.</p>

                            <p>Please read our terms and policies for more information.</p>

                            <h5 style={{ fontWeight: "bold" }}>After I lose access to the class, will I still be able to access my certificate?</h5>

                            <p><strong>Yes. You will always have access to request duplicates </strong>of your certificate.</p>

                            <h5 style={{ fontWeight: "bold" }}>Since this is a pre-recorded online training, will I be able to ask the trainer questions?</h5>

                            <p>Yes. As you watch the video, there is a Q&amp;A section under the video. You can ask questions in the Q&amp;A section. The trainer, fellow artists, or one of our in-house PMU Experts will answer your questions. &#x1F60A;&#x1F44D;</p>

                            <h5 style={{ fontWeight: "bold" }}>Will the trainer provide extra support after I complete the masterclass?</h5>

                            <p><strong>Yes! 😊👍 All of our trainers agree to be reasonably available for you to contact them via email or direct message via Instagram or Facebook</strong> after you take this training.</p>

                            <p>However, <strong>please try to ask most of your questions in the Q&amp;A section below the video.</strong> That way everybody can benefit from reading your questions and seeing the trainers answers.</p>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        {
                            showPurchasingArea() &&
                            <PurchaseArea />
                        }
                    </Row>

                    <HorizontalBanner bannerImage="/banners/horizontal-banner-2.jpg" url="https://www.allthingsshea.org/collections/pmu-aftercare" />

                    <Row style={{ backgroundColor: Constants.LightGray }} className="my-3">
                        <Col xs="12" className="justify-content-center align-items-center my-3">
                            {Constants.COPYRIGHT_TEXT}
                        </Col>
                    </Row>
                </Container>
            }
        </div >
    )
}

export default ViewClass