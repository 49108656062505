import React from 'react'
import { Image } from 'react-bootstrap'

const LoadingComponent = (props: { isLoading: boolean, component: any }) => {

    return (
        <div>
            {
                !props.isLoading &&
                props.component
            }
            {
                props.isLoading &&
                <Image src="/30.gif" />
            }
        </div>
    )


}
export default LoadingComponent