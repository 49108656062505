import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom'

import './App.css';
import CreateClass from './pages/createclass';
import { Container, Row, Col, Modal, ModalBody } from 'react-bootstrap';
import ViewClass from './pages/viewClass';
import firebase, { auth } from 'firebase';
import PMUNavbar from './components/NavBar';
import Dashboard from './pages/dashboard';
import Submitted from './pages/submitted';
import SignIn from './pages/signin';
import AuthGuard from './components/authGuard/authGuard';
import TakeModule from './pages/takeModule';
import PaymentSuccess from './pages/paymentSuccess';
import FireService from './firebaseService';
import { Constants } from './objects/constants';
import ClassCertificates from './pages/certificate';
import GiveClass from './pages/giveclass';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react'
import Success from './pages/success';
import LiveChat from 'react-livechat'
import { Verify } from 'crypto';
import VerifyCertificate from './pages/verifyCertificate';
import { UserDocument } from './objects/userDocument';
import StripeUtils from './components/stripe-utils';
import AlreadyPurchasedModal from './components/alreadyPurchasedModal';
import Utils from './utils/images';
import MarketplacePage from './pages/marketplace/marketplacePage';
import { hotjar } from 'react-hotjar'


const App = () => {

  const [userDocument, setUserDocument] = useState<any>({})

  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0()

  const history = useHistory()

  const [showAlreadyPurchasedPrompt, setShowAlreadyPurchasedPrompt] = useState(false)

  const [processingPurchaseMasterclassId, setProcessingPurchaseMasterclassId] = useState<string>()

  const [showProcessingModal, setShowProcessingModal] = useState(false)

  const navigateToRedirectUrl = () => {
    const redirectUrl = localStorage.getItem(Constants.REDIRECT_URL)
    console.log('Redirect URL is ', redirectUrl)
    if (redirectUrl) {
      history.push(redirectUrl);
      localStorage.removeItem(Constants.REDIRECT_URL)
    }
  }

  /** 
    * We need to check to see if the user has been authenticated using Auth0, and if so, then authenticate the logged in user using
    * Firebase.  If the user is not authenticated using Firebase, then they will not be able to access the application
    */
  useEffect(() => {

    (async () => {
      try {
        const domain = "thepmucon.us.auth0.com";

        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        fetch(window.location.origin.indexOf('masterclasses') == -1 ? "http://localhost:3001/firebase" : 'https://masterclasses.herokuapp.com/firebase', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(res => res.json())
          .then(res => {
            firebase.auth().signInWithCustomToken(res.firebaseToken).then(userCredentials => {

              if (!userCredentials.user) {
                FireService.logout(logout)
                return
              }

              const userId = userCredentials.user.uid

              if (userCredentials.user) {
                saveUserOnServer(userCredentials.user).then(() => {
                  storeUserObject(userId)
                  navigateToRedirectUrl()
                })
              }
            })
          })
      } catch (error) {
        // If there is an error, that means that for some reason the access token either could not be retrieved or the user could not be logged in again        
        console.log(error.message)
      }
    })();

  }, [getAccessTokenSilently])

  /**
   * Creates or updates a document for a user.  
   * It's safe to run this even if the user already has been saved before because it simply
   * updates the document with the id of the firebase User, so it's safe
   * @param user The firebase user to create/update the document for.  
   */
  const saveUserOnServer = async (user: firebase.User) => {
    var db = firebase.firestore()

    const result = await db.collection(Constants.USERS_COLLECTION).doc(user.uid).get()

    if (result.exists) { return }

    await db.collection(Constants.USERS_COLLECTION).doc(user.uid).set({
      id: user.uid,
      tags: { }
    }, { merge: true })

  }

  /**
   * Gets a user's document from the database and then sets the local user object
   * @param userId Id of the user to set local user document for
   */
  const storeUserObject = (userId: string) => {
    var db = firebase.firestore()
    db.collection(Constants.USERS_COLLECTION).doc(userId).onSnapshot(user => {
      setUserDocument(user.data())
    })
  }

  useEffect(() => {
    hotjar.initialize(2046095, 0)
    FireService.initializeFirebaseApp()

    firebase.auth().onAuthStateChanged(async (user) => {

      // User has signed out
      if (!user) {
        setUserDocument({})
      }
      else // User has signed in
      {
        handleFinishStripePayment()
        storeUserObject(user.uid)

        const isBanned = await FireService.isBanned(user.uid, logout)
        if (isBanned) { return }

        FireService.checkIfIpIsValid(user.uid).then(result => {
          if (result.isValid) { return }
          FireService.logout(logout)
        })
      }
    })
  }, [])

  /**
   * if there is a Stripe payment in process then this method does the following:
   * 
   * 1. Gets the purchaseId and the classId if there are one so that we know what the user is purchasing
   * 2. Takes the user to the Stripe Checkout Page
   * @param user 
   */
  const handleFinishStripePayment = () => {

    let freeClassId = localStorage.getItem(Constants.FREE_CLASS_ID)

    // If the user is in the process of getting a free class then just give the user the class and then exit out the function
    if (freeClassId) {       
      Utils.addClassToPurchasedClassesForCurrentUser(freeClassId)
      StripeUtils.removeSession()
      Utils.removeStripeDataFromLocalStorage()
      return
    }


    // If the user is purchasing a class, then handle the handover to stripe here

    let couponId = localStorage.getItem(Constants.COUPON_ID)
    let classId = localStorage.getItem(Constants.CLASS_ID)

    if (!classId) { return }

    setShowProcessingModal(true)
    StripeUtils.addClassIdToUserForPurchasing(classId).then(sessionId => {
      localStorage.setItem(Constants.STRIPE_SESSION_ID, sessionId)
      const successUrl = Utils.isDebug() ? Constants.STRIPE_SUCCESS_URL_DEBUG : Constants.STRIPE_SUCCESS_URL_PROD      
      StripeUtils.process({ classId: classId, couponId: couponId }, Constants.STRIPE_CANCEL_URL, successUrl, sessionId)
    }).catch(error => {
      setShowProcessingModal(false)
      if (error == Constants.Errors.ALREADY_PURCHASED) {
        if (!classId) { return }
        setShowAlreadyPurchasedPrompt(true)
        setProcessingPurchaseMasterclassId(classId)        
        StripeUtils.removeSession()
        Utils.removeStripeDataFromLocalStorage()
      }
    })
  }

  return (
    <div>
      {
        processingPurchaseMasterclassId && showAlreadyPurchasedPrompt &&
        <AlreadyPurchasedModal message="You've already purchased this class.  Would you like to go there now?" classId={processingPurchaseMasterclassId} close={ () => setShowAlreadyPurchasedPrompt(false) } />
      }

      <LiveChat license='11554178' />
      <PMUNavbar />
      <Container style={{ maxWidth: "1200px" }}>
        <Modal size="sm" show={ showProcessingModal } centered>
          <ModalBody>
              Navigating to payment page...
          </ModalBody>
        </Modal>
        <Row>
          <Col>
            <Switch>
              <Route path="/marketplace">
                <MarketplacePage />
              </Route>
              <Route path="/verify">
                <VerifyCertificate />
              </Route>
              <Route path="/success">
                <Success />
              </Route>
              <Route path="/giveclass/">
                <AuthGuard component={<GiveClass />} />
              </Route>
              <Route path="/certificate/:tag/:classId">
                <AuthGuard component={<ClassCertificates userDoc={userDocument} />} />
              </Route>
              <Route path="/certificate/:tag">
                <AuthGuard component={<ClassCertificates userDoc={userDocument} />} />
              </Route>
              <Route path="/submitted">
                <Submitted />
              </Route>
              <Route path="/createclass/:classId/">
                <AuthGuard component={<CreateClass />} />
              </Route>
              <Route path="/createclass">
                <AuthGuard component={<CreateClass />} />
              </Route>
              <Route path="/class/purchase/:classId">
                <ViewClass userDoc={userDocument} isPurchasing={true} />
              </Route>
              <Route path="/class/review/:classId">
                <AuthGuard component={<ViewClass userDoc={userDocument} review={true} />} />
              </Route>
              <Route path="/class/:classId/:className/:classInProcess">
                <AuthGuard component={<ViewClass userDoc={userDocument} />} />
              </Route>
              <Route path="/module/:moduleId/:classId">
                <AuthGuard component={<TakeModule userDoc={userDocument} />} />
              </Route>
              <Route path="/signin/purchasing">
                <SignIn isPurchasing={true} freeClass={false} />
              </Route>
              <Route path="/signin">
                <SignIn freeClass={false} />
              </Route>
              <Route path="/paymentsuccessful/:sessionId">
                <AuthGuard component={<PaymentSuccess />} />
              </Route>
              <Route path="/">
                <AuthGuard component={<Dashboard userDoc={userDocument} />} />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>

    </div>

  )

}

export default App;
