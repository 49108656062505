import React, { useEffect } from 'react'
// Import FirebaseAuth and firebase.
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Utils from '../utils/images';
import { Constants } from '../objects/constants';
import FireService from '../firebaseService';
import { useAuth0 } from '@auth0/auth0-react';

const SignIn = (props: { isPurchasing?: boolean, couponId?: string, classId?: string, close?: any, freeClass: boolean }) => {

    const redirectUrl = new URLSearchParams(window.location.search).get("redirectUrl")

    const { redirected } = useParams()

    const history = useHistory()

    const { loginWithRedirect } = useAuth0()

    FireService.initializeFirebaseApp()

    /** 
     * Login the user using Auth0
     * If the user is currently logging in so they can make a purchase then we need to save the information that they're purchasing so that when the user is redirected back to our web app then we know what is being purchased
     */
    const loginWithAuth0 = () => {

        if (props.freeClass && props.classId) {
            localStorage.setItem(Constants.FREE_CLASS_ID, props.classId)
        }

        if (props.classId) {            
            localStorage.setItem(Constants.CLASS_ID, props.classId)            
        }

        if (props.couponId) {
            localStorage.setItem(Constants.COUPON_ID, props.couponId)
        }

        loginWithRedirect()
    }

    // Configure FirebaseUI. 
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: props.isPurchasing ? 'popup' : 'redirect',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        // signInSuccessUrl: `/${redirectUrl ?? '/'}`,
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false
        }

    };

    return (
        <div>
            {/* <div>
                <Button variant="dark" onClick={props.close} className="font-weight-bold">X</Button>
            </div> */}
            <Container className="mt-5">

                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Image height="150px" src='/logo.png' />
                    </Col>
                    <Col xs="12" className="mt-3">
                        {
                            !props.isPurchasing &&
                            <div>
                                <h1 style={{ fontWeight: "normal", textAlign: "center" }}>Welcome to</h1>
                                <h1 style={{ fontWeight: "normal", textAlign: "center" }}>PMU Masterclasses</h1>
                            </div>
                        }
                        {
                            props.isPurchasing &&
                            <p style={{ textAlign: "center" }}>In order to purchase this class you must be logged in.</p>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <p style={{ textAlign: "center" }}>Please sign-in or create an account:</p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs="12" md="auto" style={{ textAlign: "center" }}>
                        <Button style={{ width: "250px", backgroundColor: Constants.Purple, border: "none" }} onClick={() => loginWithAuth0()}>
                            <span className="sign-in-button-text"><strong>Log Into PMU Masterclasses</strong></span>
                        </Button>
                    </Col>
                </Row>
                {
                    props.isPurchasing &&
                    <Row className="justify-content-md-center mt-3" >
                        <Col xs="12" md="auto" style={{ textAlign: "center" }}>
                            <Button style={{ width: "250px" }} variant="light" onClick={() => props.close() }>
                                <span className="sign-in-button-text"><strong>Cancel</strong></span>
                            </Button>
                        </Col>
                    </Row>
                }
            </Container>
        </div>

    );

}

export default SignIn