import React from 'react'
import { Accordion, Card, Button, Container, Row, Col } from 'react-bootstrap'
import CreateModule from '../components/createModule/createModule'
import AccordionSection from './sections/accordionSection'
import { Masterclass, ClassModule } from '../objects/masterclass'

const CreateModuleAccordion = (props: { classModule: ClassModule, classId?: string }) => {

    return (
        <div>
            <Accordion defaultActiveKey="3" className="my-5">
                <Card>
                    <Card.Header style={{ color: "white", backgroundColor: "#8e2d8e" }}>
                        <strong>{props.classModule.name ?? "Create"} Module - { props.classModule.id }</strong>
                    </Card.Header>

                </Card>

                <AccordionSection
                    eventKey="0"
                    finished={props.classModule != null}
                    saveButtonText="Save Module"
                    headerText="Click to add module information"
                    component={<CreateModule classModule={props.classModule} classId={props.classId} />}
                />

            </Accordion>
        </div>
    )

}

export default CreateModuleAccordion