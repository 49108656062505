import firebase from 'firebase'
import { setuid } from 'process'
import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap'
import SimpleInput from '../components/form/input'
import { Constants } from '../objects/constants'
import { ClassCertificate } from '../objects/masterclass'
import { UserDocument } from '../objects/userDocument'

const VerifyCertificate = () => {

    const [certificateId, setCertificateId] = useState("")

    const [error, setError] = useState("")

    const [certificate, setCertificate] = useState<ClassCertificate>()

    const [user, setUser] = useState<UserDocument>()

    const [invalidCertificate, setInvalidCertificate] = useState(false)

    const result = useRef<HTMLDivElement>(null)

    const [loading, setLoading] = useState(false)

    // The id that the user has checked for verification
    const [checkedCertId, setCheckedCertId] = useState("")

    useEffect(() => {
        setError("")
    }, [certificateId])

    /**
     * Scroll to where the box displays that says either invalid or valid certificate
     */
    const scrollToResult = () => {
        result.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    /** Remove any unecessary data from the screen so as not to confuse the client */
    const reset = () => {
        setCertificate(undefined)
        setUser(undefined)
        setInvalidCertificate(false)
        setError("")
    }

    /**
     * Verify a certificate if they've actually entered a certificate id already
     */
    const verify = () => {

        if (!certificateId) {
            setError("Please enter a certificate id.")
            return
        }

        setLoading(true)
        reset()

        setCheckedCertId(certificateId)

        firebase.firestore().collection(Constants.VERIFIED_CERTIFICATES).doc(certificateId.toLowerCase()).get().then(async doc => {

            setLoading(false)

            // If this document doesn't exists that's because this certificate is not verified
            if (!doc.exists) {
                userIsInvalid()
                scrollToResult()
                return
            }

            let verifiedCertificate = doc.data()

            // This check is just to make sure that if in the off chance there's corrupted data then the app doesn't explode
            if (verifiedCertificate) {
                const certificateSnapshot = await firebase.firestore().collection(Constants.CERTIFICATES_COLLECTION).doc(verifiedCertificate.certificateId).get()
                const certificate = certificateSnapshot.data()

                const userSnapshot = await firebase.firestore().collection(Constants.USERS_COLLECTION).doc(verifiedCertificate.userId).get()
                const user = userSnapshot.data()

                if (certificate && user) {
                    setCertificate(certificate as ClassCertificate)
                    setUser(user as UserDocument)
                    setInvalidCertificate(false)
                }

                // Scroll the user to where the result of whether the id is valid or not is displayed
                scrollToResult()
            } else {
                setError("It seems that there is an issue with this certificate. Please contact us at info@pmumasterclasses.com so we can try to help resolve this issue.")
            }
        }).catch(error => {
            setLoading(false)
            setError("Uh oh. Something went wrong when trying to verify the certificate. This doesn't mean that the certificate is invalid, but something happened during the verification process.  Please click the 'Verify Certificate' button to try again.  If the problem continues, please contact us at info@pmumasterclasses.com.")
        })
    }

    const userIsInvalid = () => {
        setCertificate(undefined)
        setUser(undefined)
        setInvalidCertificate(true)
    }

    return (

        <Container>
            <Row className="mt-5">
                <Col xs="12" className="d-flex justify-content-center">
                    <Image src="/pmucerts.png" thumbnail height={200} width={200} />
                </Col>
            </Row>
            <Row className="text-align-center">
                <Card className="mt-5">
                    <Card.Body>
                        <Col xs="12" className="mt-3">
                            <strong><h1 style={{ color: Constants.Purple }}>Welcome to PMU Certs!</h1></strong>
                        </Col>
                        <Col xs="12" className="mt-3">
                            <p>
                                To protect yourself from injury, experts recommend you ONLY allow properly trained and certified PMU artists work on your body. PMUCerts.com is the first provider of digitally verified certificate for the permanent make-up industry.
                            </p>
                            <p>
                                Not every artist who claims to be certified is. Digital verification helps protects you from counterfeit certificates.
                            </p>

                            <p>
                                To verify the certificate you're looking at now, enter the Certificate ID from the PMU Certs badge into the box below.
                            </p>
                        </Col>
                        <Col xs="12" className="mt-3 text-align-center">
                            <SimpleInput
                                value={certificateId ?? ""}
                                headerName="Please type the 'Certificate Id' that you see in the PMU CERTS badge at the bottom right of the certificate. A REAL 'Verified by PMU Certs' certificate will always have a 'Certificate Id'. WARNING: If the certificate does NOT have a 'Certificate Id' it is NOT a valid PMU Certs certificate."
                                placeholder="Enter the certificate id"
                                header={false}
                                onChange={setCertificateId} />
                        </Col>
                        {
                            error &&
                            <Col xs="12" className="mt-3">
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            </Col>
                        }
                        {
                            !loading &&
                            <Col xs="12" className="mt-5 mb-3">
                                <Button style={{ backgroundColor: Constants.Purple, border: "none" }} onClick={verify}>Verify Certificate</Button>
                            </Col>
                        }
                        {
                            loading &&
                            <Col xs="12" className="mt-5 mb-3">
                                <Image className="mr-3" src="/45.gif" height={50} width={50} />
                                <span>
                                    Verifying certificate...
                                </span>
                            </Col>
                        }
                    </Card.Body>
                </Card>

            </Row>
            <div ref={result}>
                {
                    user && certificate &&
                    <div>
                        <Row>
                            <Col xs="12" className="my-5" style={{ backgroundColor: Constants.Purple, color: "white" }}>
                                <div className="jumbotron mt-2" style={{ backgroundColor: Constants.Purple, color: "white" }}>
                                    <h1 className="display-4">This Certificate Is Valid!</h1>
                                    <p className="lead"></p>
                                    <hr className="my-4" style={{ backgroundColor: "white" }} />
                                    <p>The artists who presented this certificate is actually certified by PMU Certs!  You can feel safe knowing that she is a legitimate and knowledgable artist in this industry!</p>
                                    <Card style={{ color: 'black' }} className="mt-5">
                                        <Card.Header>
                                            <Col xs="12" className="mt-4">
                                                <h3 style={{ color: Constants.Purple }}> {user.certName} </h3>
                                            </Col>
                                        </Card.Header>

                                        <Card.Body>
                                            <Col xs="12" className="mt-1">
                                                <div><strong>Date Certificate Received:</strong> {certificate.date}</div>
                                            </Col>
                                            <Col xs="12" className="mt-2">
                                                <div><strong>Training Received:</strong> {certificate.service}</div>
                                            </Col>
                                            <Col xs="12" className="mt-2">
                                                <div><strong>Artist received training from:</strong> {certificate.trainer}</div>
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>

                        </Row>
                    </div>
                }
                {
                    invalidCertificate &&
                    <Row>
                        <Col xs="12" className="mt-3">
                            <div className="jumbotron mt-2" style={{ backgroundColor: Constants.Red, color: "white" }}>
                                <h1 className="display-4">This Certificate Is INVALID</h1>
                                <p className="lead"></p>
                                <hr className="my-4" style={{ backgroundColor: "white" }} />
                                <h3>You entered <strong>{checkedCertId}</strong>. </h3>
                                <p>This certificate is INVALID.  Please let the artist know that this certificate is invalid.  They can then reach out to us and we can see if this is by mistake or if this certificate is fraudulent.</p>
                            </div>
                        </Col>
                    </Row>
                }
                <Row>

                    <Card className="mt-5">
                        <Card.Body>
                            <Col>

                                <p>
                                    <strong>HOW TO VERIFY A REAL PMU CERTS Certificate</strong>
                                </p>

                                <ol>
                                    <li>
                                        You must be able to check the certificate on your PERSONAL mobile device or computer. The artist should NEVER tell you that you have to use his or her computer or mobile device.
                                    </li>
                                    <li>
                                        The website to verify the certificate MUST BE, PMUCERTS.COM. Your browser will show that this page is SECURE.
                                    </li>
                                    <li>
                                        The certificate should have the "VERIFIED BY PMUCERTS.COM" watermark. This watermark prevents an artist from swiping another artist's certificate.
                                    </li>
                                    <li>
                                        The certificate will have an easily visible "Certificate ID" somewhere on the front of the certificate. If the artists says, the ID can't be seen because it's on the back, that is NOT true. Our IDs are always in a visible area on the front of the certificate, easy for you to find.
                                    </li>
                                    <li>
                                        When you enter the "Certificate ID" in the box below, you should see a message that says, "VERIFIED". If you get any message other than verified, please contact us or do further investigation.
                                    </li>
                                </ol>
                                <p>
                                    WARNING: After the certificate is verified, the artist name, technique or topic name, and trainer name should ALL MATCH what's on the certificate. Even if the certificate is marked as verified, this certificate is INVALID if ANY of the above listed values don't match or are MISSING on the certificate.
                                </p>
                            </Col>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="mt-5">
                    <div className="alert alert-success">
                        <p>
                            <strong>IMPORTANT DISCLAIMER :: PLEASE READ</strong>
                        </p>
                        <p>
                            While we can verify the authenticity of the certificate. This is NOT A WARRANTY OF LEVEL OR QUALITY OF SERVICE. PMU CERTS MAKES NO EXPRESS OR IMPLIED WARRANTIES or representations regarding the quality of service of ANY specific artist.
                                </p>

                        <p>
                            The skill of each artist varies. Two artists can attend the exact same training but develop vastly different skill levels depending on experience, individual aptitude, tools, and countless other factors.
                                </p>

                        <p>
                            When choosing an artist, CERTIFICATION IS ONLY ONE of MANY factors to consider. Make sure you DO ADDITIONAL RESEARCH. For example, verify that the artist has a valid and current license from your local health department. Look at reviews on popular review sites as well as among organizations such as the Better Business Bureau. In addition look at factors such as: years of experience, before-and-after images, etc.
                    </p>
                    </div>
                </Row>
            </div>
        </Container>


    )

}

export default VerifyCertificate