import React from 'react'
import { Accordion, Card, Button, Image } from 'react-bootstrap'
import SaveButton from '../saveButton'
import '../accordion/accordion.css'

const AccordionSection = (props: { 
    eventKey: string, 
    finished: Boolean,
    saveButtonText: string,
    headerText: string,
    component: JSX.Element
}) => {
    return (
        <Card>
            <Card.Header className="no-border section-header" style={{ backgroundColor: "white" }}>
                <Accordion.Toggle as={Card.Header} variant="link" eventKey={props.eventKey}>
                    <Image src = "/right-arrow.png" height="10" width="10" className="mr-2" ></Image>
                    {props.headerText}
                    {
                        props.finished &&
                        <Button style={{ backgroundColor: "#2cb86b", border: "none" }} className="rounded-pill mx-3" >Filled Out</Button>
                    }
                    {
                        props.finished == false &&
                        <Button style={{ backgroundColor: "gray", border: "none" }} className="rounded-pill mx-3" >Not Filled Out Yet</Button>
                    }
                        </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body>
                    {props.component}                    
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionSection