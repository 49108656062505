import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Constants } from '../objects/constants'
import firebase from 'firebase'
import { Masterclass, ClassCertificate } from '../objects/masterclass'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import SimpleInput from '../components/form/input';
import { UserDocument } from '../objects/userDocument'
import { v4 as uuidv4 } from 'uuid'
import FireService from '../firebaseService'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'

const ClassCertificates = (props: {
    userDoc: UserDocument
}) => {

    /** The tag for the certificates.  So for example if they're for a conference, then the tag should be conference 
     * It's based off of how cerificates are tagged in the database, the document structure for tags as an example would be something along these lines:
     * tag {
     *  conference: true
     * }
    */
    const { tag } = useParams()

    /** The id of the class that this certificate is for */
    const { classId } = useParams()

    /** A list of all the certificates that are for the tag.  See the documentation for tag above. */
    const [certificates, setCertificates] = useState<ClassCertificate[]>([])

    /** The name of the class that the user is getting the certificates for */
    const [className, setClassName] = useState("")

    /** Set to true when the certificates are finished being generated and sent via email   */
    const [finished, setFinished] = useState(false)

    /** The name to be on the certificate */
    const [name, setName] = useState(props.userDoc.certName)

    /** The name that is to go on the user's certificate.  This can only be set once. */
    const [certName, setCertName] = useState(props.userDoc.certName)

    /** The email to send the certificates to */
    const [email, setEmail] = useState("")

    /** Whether or not the certificates are in the sending state */
    const [loading, setLoading] = useState(false)

    /** Any errors that happen during the generation and sending of certificates process */
    const [error, setError] = useState("")

    /** Any errors that make the page unusable, ex. User has not finished the class, or the url is incorrect, or class id is incorrect */
    const [fatalIssue, setFatalIssue] = useState("")

    const { user } = useAuth0()

    /** The email to display to the user in case they have issues */
    const supportEmail = 'info@thepmuconference.com'

    useEffect(() => {
        
        if (classId) {
            checkIfUserHasFinishedClass(classId)
        }

        var db = firebase.firestore()

        // If the user is not an attendee for this months conference then set fatalIssue
        if (!classId) {
            
            db.collection(Constants.VALID_ATTENDEES).doc(user.email).get().then(snapshot => {
                if (!snapshot.exists) {
                    setFatalIssue("Sorry! Our records show you did not attend our 2021 PMU Conference. Therefore, you cannot retrieve certificates for this year's conference. If this is an error, please email us at info@thepmuconference.com.")
                }
            })
        }        

        db.collection(Constants.CERTIFICATES_COLLECTION).where(`tags.${tag}`, '==', true).get().then(snapshot => {
            const docs = snapshot.docs
            if (docs.length > 0) {
                const certificates = docs.map(doc => doc.data() as ClassCertificate).filter(cert => cert.certificateUrl != undefined)
                setCertificates(certificates)
            }
        })
    }, [])

    const checkIfUserHasFinishedClass = (classId: string) => {
        const db = firebase.firestore()
        db.collection(Constants.CLASS_COLLECTION).doc(classId).get().then(snapshot => {
            if (!snapshot.exists) { 
                setFatalIssue("This class does not exists.  Please contact us at info@pmumasterclasses.com so we can verify this is the correct URL.")
                return 
            }

            const masterclass = snapshot.data() as Masterclass
            if (!masterclass) { setFatalIssue("This class does not exists.  Please contact us at info@pmumasterclasses.com so we can help you with this issue.") }
            if (!FireService.classIsFinished(props.userDoc, masterclass)) {
                setFatalIssue("You have not finished this class yet.  Please finish the class first and then you can get your certificate.")
            }
        })
    }

    useEffect(() => {
        if (!props.userDoc.certName) { return }
        setCertName(props.userDoc.certName)
        setName(props.userDoc.certName)
    }, [props.userDoc])

    /** Email all the certificates to the user */
    const sendCertificates = () => {

        if (email && email.trim() == "") {
            setError("Please enter a valid email address")
            return
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email || !re.test(email)) {
            setError("Please enter a valid email address")
            return
        }

        if (!certName && (!name || name.length < 2 || name.trim() == "")) {
            setError("Please enter your first and last name")
            return
        }

        FireService.updateUser({
            certName: name
        })

        const promises: Promise<boolean>[] = []

        setLoading(true)
        certificates.map(certificate => {
            if (!certificate.certificateUrl) { return }
            promises.push(sendCertificate(certificate))
        })

        /** Once all the certificates have been sent then we can let the person know that they're finished */
        Promise.all(promises).then(success => {
            setLoading(false)
            setFinished(true)
        }).catch(error => {
            showError(error)
            setLoading(false)
        })
    }

    /** Send a certificate to the users email 
     * 
     * @important Make sure the certificateUrl key value is not nil
    */
    const sendCertificate = async (certificate: ClassCertificate): Promise<boolean> => {

        const cert = await firebase.firestore().collection(Constants.VERIFIED_CERTIFICATES).where('certificateId', '==', certificate.id).where('userId', '==', props.userDoc.id).get()

        let certificateVerificationId = uuidv4().substring(0, 6)

        let shouldCreateVerifiedCertificateDocument = true

        // Check to see if this specific certificate has already been generated for this user and if so than we just send the conference cert email
        if (!cert.empty) {
            for (let index = 0; index < cert.docs.length; index++) {
                const doc = cert.docs[index];
                if (doc.data()) {
                    shouldCreateVerifiedCertificateDocument = false
                    certificateVerificationId = doc.data().id
                    const date = doc.data().date

                    try {
                        await sendConferenceCertsByEmail(certificateVerificationId, certificate, date, false)
                    } catch {
                        showError(error)
                    }
                }
            }
        }

        // If the user has not already recieved this certificate
        if (!shouldCreateVerifiedCertificateDocument) {
            return Promise.resolve(true)
        }

        let validId = false

        // Make sure that the id that we set in order to verify this certificate is not a duplicate
        while (!validId) {
            const doc = await firebase.firestore().collection(Constants.VERIFIED_CERTIFICATES).doc(certificateVerificationId).get()
            if (doc.exists) {
                certificateVerificationId = uuidv4().substring(0, 6)
            } else {
                validId = true
            }
        }

        return sendConferenceCertsByEmail(certificateVerificationId, certificate, moment().format('MMMM Do YYYY'), true)
    }

    /** Let the user know that there has been an error */
    const showError = (error: string) => {
        setError(`There was an error sending your certificates by email.  Please try again.  If the problem continues, please contact us at ${supportEmail}.  If you need to contact us, please send us the following in your message - ` + error)
    }

    const sendConferenceCertsByEmail = (certificateVerificationId: string, certificate: ClassCertificate, date: String, shouldAddCertInfo: boolean): Promise<boolean> => {
        // Generate the certificates and send them through email to the client
        return new Promise(async (resolve, reject) => {
            fetch(window.location.origin.indexOf('masterclasses') == -1 ? "http://localhost:3001/sendCertificate" : 'https://masterclasses.herokuapp.com/sendCertificate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    email: email.trim(),
                    certificateId: certificateVerificationId,
                    certificateUrl: certificate.certificateUrl,
                    service: certificate.service,
                    date: date
                })
            })
                .then(res => res.json())
                .then(async res => {
                    if (res.success) {
                        // Save the certificate information
                        if (shouldAddCertInfo) {
                            try {
                                await createCertificateRetrieved(certificateVerificationId, certificate)
                            } catch {
                                showError(error)
                            }
                        }

                        resolve(true)
                    }
                }).catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * When a certificate is created with it's id we need to save the certificate information to that id
     * @param verifyId The id that people will be able to use to verify the certificate
     */
    const createCertificateRetrieved = (certificateVerificationId: string, certificate: ClassCertificate) => {
        return new Promise((resolve, reject) => {
            firebase.firestore().collection(Constants.VERIFIED_CERTIFICATES).doc(certificateVerificationId).set({
                id: certificateVerificationId,
                userId: props.userDoc.id,
                certificateId: certificate.id,
                date: moment().format('MMMM Do YYYY')
            })
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    return (
        <Container>
            {
                fatalIssue &&
                <div className="alert alert-warning my-5">
                    { fatalIssue }
                </div>
            }
            {
                !fatalIssue &&
                <div>
                    <Row>
                        <Col>
                            <div className="jumbotron mt-5" style={{ backgroundColor: Constants.Purple, color: "white" }}>
                                <h1 className="display-4" >Your Conference Certificates</h1>
                                <p className="lead"></p>
                                <hr className="my-4" style={{ backgroundColor: "white" }} />
                                <p>You did it!  You finished a masterclass!  Nice!  Now it's time to get your {certificates.length} certificates.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <Col xs="12" className="mb-1">
                                        <div>
                                            <p style={{ fontSize: "35px" }}>Get Your Certificates Emailed To You</p>
                                        </div>
                                        <hr />
                                    </Col>
                                </Card.Title>
                                {
                                    !certName &&
                                    <div>
                                        <Col xs="12" className="mb-1 mt-3">
                                            You can use this screen whenever you want to have your certificates sent to any email address you want.
                                </Col>
                                        <Col xs="12" className="mb-1 mt-3">
                                            <div className="alert alert-danger"><strong>WARNING:</strong> After we've created your certificate with your name on it, you cannot change the name.  So <strong>make sure
                                    that you type in your name correctly.</strong> </div>
                                        </Col>
                                    </div>
                                }
                                {
                                    certName &&
                                    <Col xs="12" className="my-3">
                                        <div className="alert alert-primary">
                                            <div>
                                                The name that will be on your 'the PMU Conference' certificates is: <strong>{certName}</strong>
                                            </div>
                                            <div>
                                                If you need to change the name on your certificates, please contact us at {supportEmail}
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {
                                    !certName && (!loading && !finished) &&
                                    <div>
                                        <Col xs="12">
                                            <SimpleInput
                                                value={name ?? ""}
                                                headerName="Enter name:"
                                                placeholder="Enter name for certificate"
                                                header={false}
                                                onChange={setName} />
                                        </Col>
                                    </div>
                                }

                                <div id="email-certificates-section">
                                    {
                                        !loading &&
                                        <Col xs="12">
                                            <SimpleInput
                                                value={email ?? ""}
                                                headerName="Enter email address you want us to send your certificates to:"
                                                placeholder="Enter the email to send the certificates to"
                                                header={false}
                                                onChange={setEmail} />
                                        </Col>
                                    }
                                    <Col xs="12" className="my-3">
                                        {
                                            loading &&
                                            <div>
                                                <Image src="/45.svg" width={50} height={50} className="mr-3" />
                                                <span>
                                                    Your certificates are being generated...This may take a few minutes.
                                    </span>
                                            </div>
                                        }
                                        {
                                            finished &&
                                            <div className="mt-5 alert alert-primary">
                                                <h3>
                                                    Hooray! Your certificates have been sent to you!
                                    </h3>
                                                <div>
                                                    Check your email: <strong>{email}</strong> to make sure you got them.  If you don't see your certificates in your email then check your spam folder.  If you still don't see the certificates, then come back, refresh your screen, and try again.
                                        </div>
                                                <div>
                                                    If you continue to have problems, please contact us at {supportEmail}.
                                        </div>
                                            </div>
                                        }
                                    </Col>
                                    {
                                        error &&
                                        <Col xs="12" className="mt-3">
                                            <div className="alert alert-danger">
                                                {error}
                                            </div>
                                        </Col>
                                    }
                                    {
                                        !loading && !finished &&
                                        <Col xs="12" className="my-5">
                                            <Button style={{ backgroundColor: Constants.Purple }} onClick={sendCertificates}>Click Here To Get Your Certificates Emailed To You</Button>
                                        </Col>
                                    }
                                </div>

                            </Card.Body>
                        </Card>
                    </Row>
                </div>
            }

        </Container >
    )
}

export default ClassCertificates