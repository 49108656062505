import React, { useState, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SimpleInput from '../../form/input';
import SimpleTextArea from '../../form/textArea';
import AddImage from '../../form/addImage';
import Utils from '../../../utils/images';
import firebase from 'firebase';
import { Constants } from '../../../objects/constants';
import SaveButton from '../../saveButton';

const AddCertificate = (props: { classId?: string, imageUrl?: string }) => {

    const imageRef = useRef<JSX.Element>(null)

    const [certificateImage, setCertificateImage] = useState<File | Blob | undefined>()

    const [certificateDetails, setCertificateDetails] = useState("")

    const [loading, setLoading] = useState(false)

    const inputHeader = {
        color: "#575757"
    }

    const saveCertificate = () => {
        if (!props.classId || !certificateImage) {
            return
        }

        const db = firebase.firestore()
        var imageRef = firebase.storage().ref().child(`masterclasses/${props.classId!}/certificate.jpg`)

        setLoading(true)

        imageRef.put(certificateImage).then(async snapshot => {
            let downloadUrl = await snapshot.ref.getDownloadURL()

            await db.collection(Constants.CLASS_COLLECTION).doc(props.classId).update({
                certificateUrl: downloadUrl,
                certificateDetails: certificateDetails
            })

            setLoading(false)
        }).catch(error => {
            console.log("Error saving image ", error)
        })
    }

    // When the image has been selected, than we compress it and save it as a state object
    const onImagesAdded = async (images: File[]) => {
        const utils = new Utils()

        if (images.length == 0) {
            return
        }

        // Compress the image
        const compressedImage = await utils.compressImage(images[0])

        if (!compressedImage) { return }

        setCertificateImage(compressedImage)

    }

    return (
        <Container>
            <Col xs="12" className="my-3">
                <div className="alert alert-warning text-center">Make sure that the area where the recipient's name will go on the certificate is blank.</div>
            </Col>
            <AddImage imageUrl={props.imageUrl} onImagesAdded={onImagesAdded} ref={imageRef} />

            <SimpleTextArea
                headerName="Write some information about why they need this certificate?"
                value={certificateDetails}
                placeholder="Why do artist's need this certificate?"
                header={false}
                onChange={setCertificateDetails} />

            <SaveButton loading={loading} action={saveCertificate} title="Save Certificate" />

        </Container>
    )

}

export default AddCertificate